import {createGlobalStyle} from "styled-components";

import Effra from "./Fonts/Effra/subset-Effra-Regular.woff2";
import EffraMedium from "./Fonts/Effra/subset-Effra-Medium.woff2";
import EffraHeavy from "./Fonts/Effra/Effra-Heavy.woff2";

export default createGlobalStyle`
  @font-face {
    font-family: 'Effra';
    src: url(${Effra}) format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Effra';
    src: url(${EffraMedium}) format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Effra';
    src: url(${EffraHeavy}) format('woff2');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
`;
