import React, {lazy, Suspense, useCallback, useEffect} from "react";
import {Redirect, Route, Switch, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Loader} from "@findyourcanopy/canopy-ui";

import {FullPageContainer} from "components/Container";
import {USER_GROUPS} from "constants/users";
import NotFound from "pages/Errors/NotFound";
import ServerUnavailable from "pages/Errors/ServerUnavailable";
import {setIsUserSnapInitialized} from "redux/app";
import {restoreAuthentication} from "redux/auth";
import {getBranches} from "redux/branches/selector";
import {requestVerificationProviderInformation} from "redux/company";
import analytics from "services/analytics";
import userSnap from "services/user-snap";
import intercomChat from "services/intercom-chat";

import PrivateRoute from "./PrivateRoute";

const AccountSetup = lazy(() => import("pages/AccountSetup"));
const RedirectFromPartner = lazy(() => import("pages/RedirectFromPartner"));
const Auth = lazy(() => import("pages/Auth"));
const Dashboard = lazy(() => import("pages/Dashboard"));
const ResetPassword = lazy(() => import("pages/ResetPassword"));
const VerifyEmail = lazy(() => import("pages/VerifyEmail"));
const PublicRentPassport = lazy(() => import("pages/PublicRentPassport"));

const AppRouter = () => {
  const dispatch = useDispatch();
  const {pathname} = useLocation();
  const {isAuthChecked, isAuthenticated, isGhostModeAuthenticated, profile} = useSelector(
    state => state.auth,
  );
  const {verificationProvider} = useSelector(state => state.company);

  const storageListener = useCallback(
    event => {
      if (
        event.key === "loginData" &&
        !/(auth)|(verify-email)|(password-reset)|(server-unavailable)/.test(window.location.href)
      ) {
        dispatch(restoreAuthentication());
      }
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener("storage", storageListener);

    return () => {
      window.removeEventListener("storage", storageListener);
    };
  }, [storageListener]);

  useEffect(() => {
    (async () => {
      const authData = await dispatch(restoreAuthentication());

      // FIXME: Remove request from here. Should be requested down the tree
      if (authData) {
        dispatch(getBranches());
        dispatch(requestVerificationProviderInformation());
      }
    })();
  }, [dispatch]);

  useEffect(() => {
    const logScreenTimeout = setTimeout(() => analytics.setCurrentScreen(pathname), 150);

    return () => {
      clearTimeout(logScreenTimeout);
    };
  }, [pathname]);

  const finishUserSnapInitialization = useCallback(() => {
    dispatch(setIsUserSnapInitialized(true));
  }, [dispatch]);

  useEffect(() => {
    if (
      isAuthChecked &&
      isAuthenticated &&
      verificationProvider?.isChatAvailable &&
      !isGhostModeAuthenticated
    ) {
      intercomChat.updateUserInfo(profile);
      intercomChat.show();
    } else if (window.Intercom) {
      intercomChat.hide();
    }
  }, [
    isAuthChecked,
    isAuthenticated,
    verificationProvider?.isChatAvailable,
    profile,
    isGhostModeAuthenticated,
  ]);

  useEffect(() => {
    if (isAuthChecked) {
      if ((isAuthenticated || isGhostModeAuthenticated) && profile) {
        userSnap.initialize(
          profile?.ghostId
            ? {
                user: {
                  agencyId: profile.agency?.id,
                  agentId: profile.agent?.id,
                  userGroup: USER_GROUPS.ADMIN,
                  userId: profile.ghostId,
                },
              }
            : {
                user: {
                  agencyId: profile.agency?.id,
                  agentId: profile.agent?.id,
                  email: profile.user.email,
                  role: profile.agent?.role,
                  userGroup: USER_GROUPS.AGENT,
                  userId: profile.user.id,
                },
              },
          finishUserSnapInitialization,
        );
      } else {
        userSnap.initialize({}, finishUserSnapInitialization);
      }
    }
  }, [
    finishUserSnapInitialization,
    isAuthChecked,
    isAuthenticated,
    isGhostModeAuthenticated,
    profile,
  ]);

  if (!isAuthChecked) {
    return (
      <FullPageContainer>
        <Loader />
      </FullPageContainer>
    );
  }

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <PrivateRoute path="/dashboard" redirectTo="/auth/login" component={Dashboard} />
        <PrivateRoute path="/setup" redirectTo="/auth/login" component={AccountSetup} />
        <PrivateRoute
          path="/redirect-from-partner"
          redirectTo="/auth/login"
          component={RedirectFromPartner}
        />
        <Route path="/auth" component={Auth} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/agent/password-reset" component={ResetPassword} />
        <Route path="/server-unavailable" component={ServerUnavailable} />
        <Route path="/public-rent-passport" component={PublicRentPassport} />

        {/* Start redirects from old paths */}
        <Redirect
          exact
          from="/branches/:branchId/rent-passport/:id"
          to="/dashboard/rent-passport/:id"
        />
        <Redirect exact from="/forgotPassword" to="/auth/recovery" />
        <Redirect exact from="/create-account/about" to="/auth/registration" />
        <Redirect exact from="/create-account/:id" to="/auth/registration/:id" />
        <Redirect exact from="/sign-in" to="/auth/login" />
        <Redirect exact from="/" to="/auth" />
        {/* End redirects from old paths */}

        {/* No match found => show not found page */}
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
