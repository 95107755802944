const initialState = {
  data: {
    name: "",
    address: "",
    phone: "",
  },
  editableBranch: {
    name: "",
    phone: "",
    address: {
      houseNumber: "",
      street: "",
      county: "",
      countryCode: "",
      postCode: "",
    },
  },
  isLoading: false,
  error: "",
};

export const createBranchSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getEditableBranchSuccess = (state, {payload}) => ({
  ...state,
  editableBranch: payload,
});

export const clearState = () => ({...initialState});

export default initialState;
