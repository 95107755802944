export const passportCondition = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  FAVOURITES: "FAVOURITES",
};

export const statuses = {
  IN_PROGRESS: "IN_PROGRESS",
  INVITE_SENT: "INVITE_SENT",
  NOT_STARTED: "NOT_STARTED",
  CONSIDER: "CONSIDER",
  VERIFY: "VERIFY",
  RENT_READY: "RENT_READY",
  HIGH_RISK: "HIGH_RISK",
};

export const REFERENCING_TYPES = {
  CC_ONLY: "CC_ONLY",
  FULL: "FULL",
  INSTANT: "INSTANT",
  PRE_QUALIFICATION: "PRE_QUALIFICATION",
  PRIORITY: "PRIORITY",
};

export const addonsTypes = {
  GUARANTOR: "GUARANTOR",
  IDV: "IDV",
  RTR: "RTR",
};

export const creditCheckVerifiedStatuses = {
  AUTO_VERIFIED: "AUTO_VERIFIED",
  MANUALLY_VERIFIED: "MANUALLY_VERIFIED",
};

export const tenancyStates = {
  SCREENING: "SCREENING",
  IN_TENANCY: "IN_TENANCY",
  ARCHIVED: "ARCHIVED",
};

export const iconConfig = {
  [statuses.IN_PROGRESS]: {
    iconName: "progress",
    type: "default",
    textKey: "inProgress",
    isPrimary: true,
  },
  [statuses.VERIFY]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "accept",
    isPrimary: true,
  },
  [statuses.RENT_READY]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "rentReady",
    isPrimary: true,
  },
  [statuses.INVITE_SENT]: {
    iconName: "email",
    type: "default",
    textKey: "inviteSent",
    isPrimary: true,
  },
  [statuses.NOT_STARTED]: {
    iconName: "close-circle-fill",
    type: "default",
    textKey: "notStarted",
    isPrimary: true,
  },
  [statuses.CONSIDER]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "accept",
    bottomNoteKey: "withConsideration",
    bottomIconName: "attention-fill",
    bottomIconType: "warning",
    isPrimary: true,
  },
  [statuses.HIGH_RISK]: {
    iconName: "attention-fill",
    type: "error",
    textKey: "highRisk",
    isPrimary: true,
  },
};

export const overallPreQualifiedStatusConfig = {
  [statuses.NOT_STARTED]: {
    type: "default",
    iconName: "close-circle-fill",
    textKey: "notStarted",
    isPrimary: true,
  },
  [statuses.IN_PROGRESS]: {
    type: "default",
    iconName: "progress",
    textKey: "inProgress",
    isPrimary: true,
  },
  [statuses.VERIFY]: {
    type: "success",
    iconName: "tick-circle",
    textKey: "rentReady",
    isPrimary: true,
  },
  [statuses.RENT_READY]: {
    type: "success",
    iconName: "tick-circle",
    textKey: "rentReady",
    isPrimary: true,
  },
  [statuses.INVITE_SENT]: {
    iconName: "email",
    type: "default",
    textKey: "inviteSent",
    isPrimary: true,
  },
  [statuses.CONSIDER]: {
    type: "success",
    iconName: "tick-circle",
    textKey: "rentReady",
    bottomIconType: "warning",
    bottomIconName: "tick-circle",
    bottomNoteKey: "withConsideration",
    isPrimary: true,
  },
  [statuses.HIGH_RISK]: {
    type: "error",
    iconName: "attention-status",
    textKey: "highRisk",
    isPrimary: true,
  },
};

export const PERMISSION_TYPES = {
  PRE_QUALIFICATION_SCREENING: "PRE_QUALIFICATION_SCREENING",
  INSTANT_SCREENING: "INSTANT_SCREENING",
  FULL_SCREENING: "FULL_SCREENING",
  PRIORITY_SCREENING: "PRIORITY_SCREENING",
};

export const ACCESS_LEVELS = {
  ...PERMISSION_TYPES,
  INVITED: "INVITED",
  REJECTED: "REJECTED",
};

export const GUARANTOR_STATUS_ASIDE_CONTENT_BLOCK_ID = "guarantor-status-aside-block";

export const rpQueryParams = {
  RESULT: "result",
  TENANT_STATUS: "tenantStatus",
  MOVING_SOON: "movingSoon",
  TIER: "tier",
  COMP_OUTSTANDING: "compOutstanding",
  IDV_REQUESTED: "idvRequested",
  DOWNLOAD_PDF: "downloadPDF",
};

export const rpQueryParamsOrder = [
  rpQueryParams.RESULT,
  rpQueryParams.TENANT_STATUS,
  rpQueryParams.MOVING_SOON,
  rpQueryParams.TIER,
  rpQueryParams.COMP_OUTSTANDING,
  rpQueryParams.IDV_REQUESTED,
  rpQueryParams.DOWNLOAD_PDF,
];

export const TENANCY_STATUS_CHANGE_OPTIONS = {
  ARCHIVE: "ARCHIVED",
  FINALISE: "IN_TENANCY",
  REOPEN: "SCREENING",
};
