import {formatPayment} from "utils/currency";
import {DATE_FORMAT_MONTH, formatDate} from "utils/date";

export const formatPrice = (price, free, vat) => {
  if (price === 0) {
    return free;
  }

  return `${formatPayment({amount: price})} + ${vat}`;
};

export const formatUntil = (date, until) => {
  if (date) {
    return `${until} ${formatDate({date, dateFormat: DATE_FORMAT_MONTH})}`;
  }

  return null;
};

export const prepareValuesForSendRpRequest = (values, isPropertiesAvailable) => {
  const trimmedValues = {
    ...values,
    moveInAddress:
      values.moveInAddress && !isPropertiesAvailable
        ? {...values.moveInAddress, value: undefined}
        : undefined,
    firstName: values.firstName?.trim(),
    lastName: values.lastName?.trim(),
    email: values.email?.trim(),
    phone: values.phone?.trim(),
    validPhone: values.validPhone?.trim(),
    countryCode: values.countryCode?.trim(),
    phoneType: values.phoneType?.trim(),
    moveTo: values.isExact ? undefined : values.moveTo,
    instantScreening: undefined,
    disclaimer: undefined,
    isExact: undefined,
  };

  const cleanEmptyValues = Object.entries(trimmedValues).reduce((acc, [key, value]) => {
    if (typeof value !== "boolean" && !value) {
      acc[key] = undefined;
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});

  return cleanEmptyValues;
};
