import {connectionInviteStatuses} from "constants/jointScreening";

export const getUsersWithoutRpStateIds = jRenters => {
  const inactiveUsersIds = jRenters.reduce((acc, user) => {
    if (!user.status) {
      acc.push(user.id);
    }

    return acc;
  }, []);

  return inactiveUsersIds;
};

export const setInactiveUsersLoading = ({isLoading, inactiveIds, allRenters}) => {
  const renters = allRenters.map(user => {
    const formatUser = {...user};

    if (inactiveIds.includes(formatUser.id)) {
      formatUser.isStatusLoading = isLoading;
    } else {
      formatUser.isStatusLoading = false;
    }

    return formatUser;
  });

  return renters;
};

export const setJRentersConnectionStatus = ({inactiveIds, inactiveUsers, allRenters}) => {
  const renters = allRenters.map(user => {
    const formatUser = {...user};

    formatUser.isStatusLoading = false;

    if (inactiveIds.includes(formatUser.id)) {
      formatUser.connectionStatus = inactiveUsers[formatUser.id]?.status;
      formatUser.renterId = inactiveUsers[formatUser.id]?.renterId;
      formatUser.agentInviteId = inactiveUsers[formatUser.id]?.agentInviteId;
      formatUser.jointRenterId = inactiveUsers[formatUser.id]?.jointRenterId;
    }

    return formatUser;
  });

  return renters;
};

export const updateJRentersConnectionStatus = ({renterId, allRenters}) => {
  const renters = allRenters.map(user => {
    const formatUser = {...user};

    formatUser.isStatusLoading = false;

    if (renterId.includes(formatUser.id)) {
      formatUser.connectionStatus = connectionInviteStatuses.CONNECTION_INVITE_PENDING;
    }

    return formatUser;
  });

  return renters;
};

export const formatInactiveUsers = data => {
  const formattedData = data.reduce((acc, item) => {
    acc[item.jointRenterId] = item;

    return acc;
  }, {});

  return formattedData;
};
