export const statuses = {
  NO: "NO",
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  CANCELLED: "CANCELLED",
  BLOCKED: "BLOCKED",
  READY_TO_COMPLETE: "READY_TO_COMPLETE",
  COMPLETED: "COMPLETED",
  EXPIRED: "EXPIRED",
  PROVIDE_IN_PERSON: "PROVIDE_IN_PERSON", // READY_TO_COMPLETE with MANUAL type
};

export const types = {
  MANUAL: "MANUAL",
};

export const documentTypes = {
  PASSPORT: "PASSPORT",
  DRIVERS_LICENSE: "DRIVERS_LICENSE",
  IDCARD: "IDCARD",
  RESIDENCE_PERMIT: "RESIDENCE_PERMIT",
};

export const iconConfig = {
  [statuses.PENDING]: {
    iconName: "dots-horizontal",
    type: "default",
    textKey: "requested",
    isPrimary: true,
  },
  [statuses.ACCEPTED]: {
    iconName: "progress",
    type: "default",
    textKey: "inProgress",
    isPrimary: true,
  },
  [statuses.BLOCKED]: {
    iconName: "attention-fill",
    type: "error",
    textKey: "unableToValidate",
    isPrimary: true,
  },
  [statuses.READY_TO_COMPLETE]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "verified",
    isPrimary: true,
  },
  [statuses.DECLINED]: {
    iconName: "attention-fill",
    type: "error",
    textKey: "requestDeclined",
    isPrimary: true,
  },
  [statuses.COMPLETED]: {
    iconName: "tick-circle",
    type: "success",
    textKey: "complete",
    isPrimary: true,
  },
  [statuses.CANCELLED]: {
    iconName: "attention-fill",
    type: "warning",
    textKey: "cancelled",
    isPrimary: true,
  },
  [statuses.PROVIDE_IN_PERSON]: {
    iconName: "tick-circle",
    type: "warning",
    textKey: "provideInPerson",
    isPrimary: true,
  },
  [statuses.EXPIRED]: {
    iconName: "attention-fill",
    type: "error",
    textKey: "expired",
    isPrimary: true,
  },
};

export const rightToRentConditions = {
  ALL: "ALL",
  COMPLETE: "COMPLETE",
  ENDING_SOON: "ENDING_SOON",
};

export const rightToRentRequestTypes = {
  ALL: "ALL",
  COMPLETED: "COMPLETED",
  EXPIRED_SOON: "EXPIRED_SOON",
};

export const UK_GOV_LINK = "https://www.gov.uk/view-right-to-rent";

export const RTR_SCREENING_STATUS_BLOCK_ID = "rtr-screening-status-block";

export const RTR_ASIDE_BLOCK_ID = "rtr-aside-block";

export const ReadyToCompleteRTRDialogMod = {
  EDIT: "EDIT",
  CREATE: "CREATE",
  SET_DATE: "SET_DATE",
};
