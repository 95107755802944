import styled from "styled-components";
import {Button, Card, Flex, utils} from "@findyourcanopy/canopy-ui";

import {ReactComponent as CanopyLogo} from "assets/canopy-logo.svg";

export const Wrapper = styled(Flex.Column)`
  background-color: ${({theme}) => theme.primaryAccent};
  justify-content: center;
  width: 100%;
  min-height: 100%;
  ${utils.responsive.mobileL} {
    min-height: auto;
  }
`;

export const Logo = styled(CanopyLogo).attrs({
  alt: "logo",
})`
  margin: 95px auto 34px;
  flex-shrink: 0;
  ${utils.responsive.mobileL} {
    width: 181px;
    height: 34px;
    margin: 18px auto;
  }
`;

export const Container = styled(Card)`
  margin-bottom: 95px;
  ${utils.responsive.mobileL} {
    margin-bottom: 0;
    overflow: hidden;
  }
`;

export const Footer = styled(Card.Footer)`
  padding-top: 0;
  border: 0;
`;

export const Content = styled.div`
  margin: 48px auto 29px;
  max-width: 360px;
  width: 100%;
  flex-grow: 1;
  ${utils.responsive.mobileL} {
    margin-top: 36px;
    padding: 0 20px;
    overflow: auto;
  }
`;

export const Action = styled(Button).attrs(props => ({
  type: props.type,
}))`
  margin-top: 9px;
  margin-bottom: 14px;
  ${utils.responsive.mobileL} {
    margin-bottom: 10px;
  }
`;
