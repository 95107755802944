import {createSlice} from "@reduxjs/toolkit";

import initialState, * as handlers from "./handlers";

const localeSlice = createSlice({
  reducers: handlers,
  initialState,
  name: "locales",
});

export const {setLocale} = localeSlice.actions;

export default localeSlice.reducer;
