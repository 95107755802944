import {http} from "http/index";

import {overviewFormatter} from "./formatters";

export const getCompanyBranding = async companyId => http.get(`/company/${companyId}/branding`);

export const getCompanyOverview = async () => http.get(`/company/overview`).then(overviewFormatter);

export const getLIReferralLink = async () => http.get("/content-manager/agency-referral-link");

export const getCompanyFeatureSettings = async () => http.get("/company/feature/settings");
