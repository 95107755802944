import "react-app-polyfill/stable";

import React from "react";
import ReactDOM from "react-dom";
import {pdfjs} from "react-pdf";
import {Provider as ReduxProvider} from "react-redux";
import {CanopyGlobalStyles, Toaster} from "@findyourcanopy/canopy-ui";
import {Router as RouterProvider} from "react-router-dom";

import {DarkModeThemeProvider} from "context/DarkMode";
import configureStore from "redux/configureStore";
import "services/performance-monitoring";
import remoteConfig from "services/remote-config";
import "services/user-snap";
import "services/intercom-chat";
import history from "utils/history";

import Fonts from "./Fonts";
import "./Fonts/Fontello/css/fontello.css";
import AppRouter from "./router";

const store = configureStore();

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

remoteConfig.setupRemoteConfig();

ReactDOM.render(
  <>
    <Fonts />
    <CanopyGlobalStyles />
    <ReduxProvider store={store}>
      <DarkModeThemeProvider>
        <RouterProvider history={history}>
          <AppRouter />
          <Toaster.Container />
        </RouterProvider>
      </DarkModeThemeProvider>
    </ReduxProvider>
  </>,
  document.getElementById("root"),
);
