import getTranslations from "utils/locales";

const initialState = {
  currentLocale: "en-US",
  translations: getTranslations("en-US"),
};

export const setLocale = (state, {payload}) => ({
  ...state,
  currentLocale: payload,
  translations: getTranslations(payload),
});

export default initialState;
