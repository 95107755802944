import {formatDate, DATE_TIME_FORMAT, DATE_FORMAT_MONTH} from "utils/date";
import {addressToString} from "utils/addresses";
import {formatPayment} from "utils/currency";
import {formatCondition} from "utils/condition";
import makeFullName from "utils/makeFullName";
import {types} from "constants/references";
import {guarantorInfoTypes, guarantorStates, guarantorStatuses} from "constants/guarantors";

export const formatRentPreferences = (
  {smoking, pets, children, bedrooms = "-", furnish = "-", accessibilityRequirements = {have: "-"}},
  translations,
) => {
  const isTrue = value => !!value;
  const boolToString = bool => (isTrue(bool) ? translations.yes : translations.no);
  const boolToRequired = bool => (isTrue(bool) ? translations.required : translations.notRequired);

  return {
    smoking: boolToString(smoking),
    pets: boolToString(pets),
    children: isTrue(children.have)
      ? `${boolToString(children.have)}-${children.amount}`
      : boolToString(children.have),
    bedrooms: `${bedrooms}`,
    furnish,
    accessibilityRequirements: {
      ...accessibilityRequirements,
      value: `${
        typeof accessibilityRequirements.have === "string"
          ? accessibilityRequirements.have
          : boolToRequired(accessibilityRequirements.have)
      }`,
    },
  };
};

const baseRefFormatter = (ref, frequencyTranslations) => {
  const paymentFrequency = frequencyTranslations[ref.paymentFrequency];

  return {
    paymentFrequency,
    submittedAt: formatDate({date: ref.createdAt, fallback: null}),
  };
};

const checkWarnings = ({isAnyRentArrears, isRenterSatisfying, isPropertyDamaged, options}) => {
  const warningsObj = {
    isAnyRentArrears: isAnyRentArrears === options.truly,
    isRenterSatisfying: isRenterSatisfying === options.falsy,
    isPropertyDamaged: isPropertyDamaged === options.truly,
  };

  warningsObj.total = 0;
  Object.values(warningsObj).forEach(el => {
    if (el === true) {
      warningsObj.total += 1;
    }
  });

  return warningsObj;
};

export const formatLandlordReference = (
  {data, status},
  frequencyTranslations,
  statusesTranslations,
) => {
  const formatedReferences = data.map(ref => {
    const options = {
      truly: statusesTranslations.yes,
      falsy: statusesTranslations.no,
    };
    const isAnyRentArrears = formatCondition(ref.isAnyRentArrears, options);
    const isRenterSatisfying = formatCondition(ref.isRenterSatisfying, options);
    const isPropertyDamaged = formatCondition(ref.isPropertyDamaged, options);
    const isNoticed = formatCondition(ref.isNoticed, options);

    return {
      ...ref,
      startDate: formatDate({
        date: ref.startDate,
        fallback: formatDate({date: ref.address.startDate}),
      }),
      endDate: formatDate({
        date: ref.endDate,
        fallback: formatDate({date: ref.address.endDate}),
      }),
      landlordName: ref.fullName || "-",
      address: addressToString(ref.address),
      monthlyRentAmount: formatPayment({amount: ref.monthlyRentAmount}),
      type: types.landlord,
      isNoticed,
      isAnyRentArrears,
      isRenterSatisfying,
      isPropertyDamaged,
      warnings: checkWarnings({isAnyRentArrears, isRenterSatisfying, isPropertyDamaged, options}),
      ...baseRefFormatter(ref, frequencyTranslations),
    };
  });

  return {
    data: formatedReferences,
    status,
  };
};

export const formatEmployerReference = (
  {data, status, sources},
  frequencyTranslations,
  employmentTranslations,
  employmentTypes,
) => {
  const formatedReferences = data.map(ref => {
    const employmentStatus = employmentTranslations[ref.employmentStatus];
    const employmentType = employmentTypes[ref.employmentType];

    return {
      ...ref,
      employmentStatus,
      employmentType,
      annualSalary: formatPayment({amount: ref.annualSalary}),
      startDate: formatDate({date: ref.startDate, dateFormat: DATE_FORMAT_MONTH}),
      endDate: formatDate({date: ref.endDate, fallback: null}),
      type: types.employer,
      ...baseRefFormatter(ref, frequencyTranslations),
    };
  });

  return {
    data: formatedReferences,
    status,
    sources,
  };
};

export const formatNotes = notes => {
  const notesToFormat = {};

  Object.entries(notes).map(category =>
    Object.entries(category[1]).map(
      // eslint-disable-next-line
      type =>
        (notesToFormat[category[0]] = {
          ...notesToFormat[category[0]],
          [type[0]]: {
            message: type[1].message,
            createdAt: formatDate({date: type[1].createdAt, dateFormat: DATE_TIME_FORMAT}) || null,
          },
        }),
    ),
  );

  return notesToFormat;
};

export const formatPersonalData = ({guarantor, user}) => {
  const person = guarantor || user;

  return (
    person && {
      firstName: person.firstName || "-",
      lastName: person.lastName || "-",
      fullName: makeFullName(person),
      id: person.id,
    }
  );
};

export const formatGuarantorsInfo = (guarantorRequest, guarantors) => {
  let formattedGuarantors = [];

  if (guarantors.length === 0) {
    formattedGuarantors = [
      {
        type: guarantorInfoTypes.requested,
        actionDate: formatDate({date: guarantorRequest.requestedAt}),
      },
    ];
  } else {
    formattedGuarantors = guarantors.map(({status, guarantor, createdAt, submittedAt}) => {
      const guarantorData = formatPersonalData({guarantor});

      if (status === guarantorStatuses.ACCEPTED) {
        const common = {
          affordability: formatPayment({
            amount: guarantor.monthlyRentAffordability,
            digits: false,
          }),
          ...guarantorData,
        };

        const config = {
          [guarantorStates.NOT_STARTED]: {
            type: guarantorInfoTypes.notStarted,
            actionDate: formatDate({date: createdAt}),
            ...guarantorData,
          },
          [guarantorStates.IN_PROGRESS]: {
            type: guarantorInfoTypes.in_progress,
            actionDate: formatDate({date: submittedAt}), // TODO: change on guarantor.updatedAt after backend implement that logic
            ...guarantorData,
          },
          [guarantorStates.CONSIDER]: {type: guarantorInfoTypes.consider, ...common},
          [guarantorStates.ACCEPT]: {type: guarantorInfoTypes.accept, ...common},
          [guarantorStates.HIGH_RISK]: {type: guarantorInfoTypes.highRisk, ...common},
        };

        return config[guarantor?.state] || {};
      }

      const config = {
        [guarantorStatuses.PENDING]: {
          type: guarantorInfoTypes.invited,
          actionDate: formatDate({date: createdAt}),
        },
        [guarantorStatuses.DECLINED]: {
          type: guarantorInfoTypes.declined,
          actionDate: formatDate({date: submittedAt}),
        },
      };

      return config[status] || {};
    });
  }

  return formattedGuarantors;
};
