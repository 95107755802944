import {createSlice} from "@reduxjs/toolkit";
import {Toaster} from "@findyourcanopy/canopy-ui";

import {getAgentBranches} from "http/branches";
import analytics from "services/analytics";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {logout} from "redux/auth/actions";
import history from "utils/history";
import {backendErrors} from "constants/branchSelector";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    agentBranchesLoading: startLoading,
    agentBranchesSuccess: handlers.getBranchesSuccess,
    setActiveBranchId: handlers.setActiveBranchId,
    setAllBranchesMode: handlers.setAllBranchesMode,
    agentBranchesFailure: loadingFailed,
    clearState: handlers.clearState,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "branchSelector",
});

const getActiveBranchFromLS = branches => (dispatch, getState) => {
  const {accessToBranchDenied} = getState().locales.translations.errors;

  const loginDataRaw = localStorage.getItem("loginData");
  const loginData = JSON.parse(loginDataRaw);

  if (typeof loginData.activeBranchId === "undefined") {
    if (branches.length === 1) {
      dispatch(actions.setActiveBranchId(branches[0].id));
    } else {
      dispatch(actions.setAllBranchesMode());
    }

    return undefined;
  }

  if (branches.findIndex(el => el.id === loginData.activeBranchId) >= 0) {
    dispatch(actions.setActiveBranchId(loginData.activeBranchId));
  } else {
    history.push("/dashboard/company", {reason: backendErrors.permissionDeniedToBranch});
    analytics.logErrorWithLevel("ToastOverlay", {});
    Toaster.toastConfig.showError({title: accessToBranchDenied});
  }

  return undefined;
};

export const getBranches = () => async dispatch => {
  try {
    dispatch(actions.agentBranchesLoading());
    const data = await getAgentBranches();

    dispatch(getActiveBranchFromLS(data));
    dispatch(actions.agentBranchesSuccess(data));
  } catch (error) {
    dispatch(actions.agentBranchesFailure(extractError(error)));
  }
};

export const setActiveBranchId = id => async dispatch => {
  dispatch(actions.setActiveBranchId(id));
  const loginDataRaw = localStorage.getItem("loginData");

  if (loginDataRaw) {
    const loginData = JSON.parse(loginDataRaw);
    const newLoginData = {...loginData, activeBranchId: id};

    await localStorage.setItem("loginData", JSON.stringify(newLoginData));
  }
};

export const setAllBranchesMode = () => async dispatch => {
  dispatch(actions.setAllBranchesMode());
  const loginDataRaw = localStorage.getItem("loginData");

  if (loginDataRaw) {
    const loginData = JSON.parse(loginDataRaw);
    const newLoginData = {...loginData, activeBranchId: undefined};

    await localStorage.setItem("loginData", JSON.stringify(newLoginData));
  }
};

export const clearState = () => dispatch => {
  dispatch(actions.clearState());
};

export default reducer;
