import {
  REFERENCING_GROUPS,
  REFERENCING_SECTION_TYPES,
  WARNING_RULE_STATES,
  WARNING_MESSAGE_KEYS,
  WARNINGS_CONSIDER_SECTIONS_KEYS,
  WARNINGS_HIGH_RISK_SECTIONS_KEYS,
} from "constants/branch-screening";

const formatWarnings = (warnings = []) => {
  const result = {};

  warnings.forEach(({fieldName, ruleState, message}) => {
    result[message] = {
      fieldName,
      message: [message],
      ruleState,
    };
  });

  return result;
};

const getSummaryWarnings = referenceGroups => {
  const summary = {
    HIGH_RISK: {},
    CONSIDER: {},
  };

  referenceGroups.forEach(groupData => {
    const referenceSections = groupData?.referenceSections || [];

    if (groupData.warnings && groupData.warnings.length > 0) {
      groupData.warnings.forEach(groupWarning => {
        summary[groupWarning.ruleState] = {
          ...summary[groupWarning.ruleState],
          ...{[groupData.type]: groupWarning.message},
        };
      });
    }

    referenceSections
      .filter(sectionData => sectionData.type !== REFERENCING_SECTION_TYPES.CREDIT_CHECK)
      .forEach(sectionData => {
        if (sectionData.warnings && sectionData.warnings.length > 0) {
          sectionData.warnings.forEach(referenceSectionWarning => {
            summary[referenceSectionWarning.ruleState] = {
              ...summary[referenceSectionWarning.ruleState],
              ...{[sectionData.type]: referenceSectionWarning.message},
            };
          });
        }
      });
  });

  return {
    considerSummary: summary.CONSIDER,
    highRiskSummary: summary.HIGH_RISK,
  };
};

const getGlobalWarnings = warnings => {
  const highRiskSummaryGlobal = {};
  const considerSummaryGlobal = {};

  warnings.forEach(item => {
    if (item.ruleState === WARNING_RULE_STATES.HIGH_RISK) {
      if (item.message === WARNING_MESSAGE_KEYS.BLOCKED_FRAUD_STATUS) {
        highRiskSummaryGlobal[WARNINGS_HIGH_RISK_SECTIONS_KEYS.OVERALL] =
          WARNING_MESSAGE_KEYS.BLOCKED_FRAUD_STATUS;
      }
    }
    if (item.ruleState === WARNING_RULE_STATES.CONSIDER) {
      if (item.message === WARNING_MESSAGE_KEYS.BLOCKED_FRAUD_STATUS) {
        considerSummaryGlobal[WARNINGS_CONSIDER_SECTIONS_KEYS.OVERALL] =
          WARNING_MESSAGE_KEYS.BLOCKED_FRAUD_STATUS;
      }
    }
  });

  return {highRiskSummaryGlobal, considerSummaryGlobal};
};

const getReferenceSections = (referenceSections = []) => {
  const result = {};

  referenceSections.forEach(sectionData => {
    result[sectionData.type] = {
      ...sectionData,
      warnings: formatWarnings(sectionData?.warnings),
      sectionWarnings: sectionData?.warnings,
    };
  });

  return result;
};

const formatSectionsBasic = referenceGroups => {
  let result = {};

  referenceGroups.forEach(groupData => {
    const referenceSections = groupData?.referenceSections || [];

    result = {
      ...result,
      ...getReferenceSections(referenceSections),
    };
  });

  return result;
};

const getStatuses = referenceGroups => {
  const result = {
    [REFERENCING_GROUPS.BASIC]: {},
    [REFERENCING_GROUPS.EXTENDED]: {},
    [REFERENCING_GROUPS.OPTIONAL]: {},
  };

  referenceGroups.forEach(({type, status, warnings = {}, isCompleted = null}) => {
    result[type] = {
      status,
      warnings,
      isCompleted,
    };
  });

  return result;
};

export const formatDetails = ({
  branchId,
  canBeOverridden,
  connectionId,
  createdAt,
  id,
  isCompleted,
  monthlyRentAffordability,
  overallStatus,
  overridden,
  referenceGroups,
  userId,
  warnings,
  ...rest
}) => {
  const sections = formatSectionsBasic(referenceGroups);
  const statuses = getStatuses(referenceGroups);
  const {highRiskSummary, considerSummary} = getSummaryWarnings(referenceGroups);
  const {highRiskSummaryGlobal, considerSummaryGlobal} = getGlobalWarnings(warnings);

  const canRequestGuarantor = !sections[REFERENCING_SECTION_TYPES.GUARANTORS]; // NOTE: TEMPORARY SOLUTION

  return {
    branchId,
    canBeOverridden,
    canRequestGuarantor,
    connection: {
      referencingType: "INSTANT",
      id: connectionId,
      tenancyStatus: "SCREENING",
      moveFrom: null,
      moveTo: null,
    },
    connectionId,
    considerSummary: {...considerSummary, ...considerSummaryGlobal},
    createdAt,
    highRiskSummary: {...highRiskSummary, ...highRiskSummaryGlobal},
    isCompleted,
    isFavourite: false,
    monthlyRentAffordability,
    overallStatus,
    overridden,
    referenceGroups,
    referenceId: id,
    sections,
    statuses,
    userId,
    warnings,
    isGuarantor: rest.screeningType === "GUARANTOR" || rest.screeningType === "INDIRECT_GUARANTOR",
    ...rest,
  };
};
