export const GUARANTOR_TYPES = {
  PERSON: "PERSON",
  HOUSING_HAND: "HOUSING_HAND",
};

const guarantorInfoTypes = {
  requested: "requested",
  invited: "invited",
  declined: "declined",
  accepted: "accepted",
  in_progress: "in_progress",
  verify: "verify",
  consider: "consider",
  notStarted: "notStarted",
  highRisk: "highRisk",
};

const guarantorStatuses = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
};

const guarantorStates = {
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  CONSIDER: "CONSIDER",
  VERIFY: "VERIFY",
  HIGH_RISK: "HIGH_RISK",
};

export const iconConfig = {
  [guarantorStatuses.ACCEPTED]: {
    iconName: "tick-circle",
    isPrimary: true,
    textKey: "accepted",
    type: "success",
  },
  [guarantorStatuses.DECLINED]: {
    iconName: "attention-fill",
    isPrimary: true,
    textKey: "declined",
    type: "error",
  },
  [guarantorStatuses.PENDING]: {
    iconName: "progress",
    isPrimary: true,
    textKey: "inProgress",
    type: "default",
  },
  [guarantorStates.NOT_STARTED]: {
    iconName: "dots-horizontal",
    isPrimary: true,
    textKey: "notStarted",
    type: "default",
  },
  [guarantorStates.IN_PROGRESS]: {
    iconName: "progress",
    isPrimary: true,
    textKey: "inProgress",
    type: "default",
  },
  [guarantorStates.VERIFY]: {
    iconName: "tick-circle",
    isPrimary: true,
    textKey: "accepted",
    type: "success",
  },
  [guarantorStates.CONSIDER]: {
    iconName: "tick-circle",
    isPrimary: true,
    textKey: "accepted",
    type: "success",
    bottomNoteKey: "withConsideration",
    bottomIconName: "attention-fill",
    bottomIconType: "warning",
  },
  [guarantorStates.HIGH_RISK]: {
    iconName: "attention-fill",
    isPrimary: true,
    textKey: "highRisk",
    type: "error",
  },
};

export const statusIconConfig = {
  ...iconConfig,
  [guarantorStates.CONSIDER]: {
    iconName: "attention-fill",
    isPrimary: true,
    textKey: "consider",
    type: "warning",
  },
};

export {guarantorInfoTypes, guarantorStatuses, guarantorStates};
