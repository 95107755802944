/**
 * Format Conditional value to string
 * @params {value: boolean, {truly: string, falsy: string}}
 * @returns string with provided params or "-"
 */
export const formatCondition = (value, options) => {
  if (!options) {
    return "error";
  }
  if (typeof value === "boolean") {
    return value ? options.truly : options.falsy;
  }

  return "-";
};
