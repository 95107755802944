import {http} from "http/index";
import qs from "utils/query";

export const checkEmailAvailability = email =>
  http.post("/agent/user/check-email-availability", {email});

export const resendVerificationEmail = ({email}) =>
  http.post("/agent/user/resend-activation", {email});

/**
 * Requests information about the renter with ones screening type
 */
export const getRenterInfoById = ({branchId, guarantor, renterId}) =>
  http.get(
    `/agent/user/${renterId}${qs.stringify({branchId, guarantor: !!guarantor}, {skipNulls: true})}`,
  );
