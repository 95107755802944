import {createSlice} from "@reduxjs/toolkit";
import {Toaster, DownloadToaster} from "@findyourcanopy/canopy-ui";
import {FORM_ERROR} from "final-form";
import get from "lodash/get";

import {
  getDetails,
  getDetailsBranchConnections,
  create,
  remove,
  update,
  removeRenter,
  updateRenter,
  addRenter,
  downloadPDF,
  getUsersConnectionStatuses,
  resendInvite,
} from "http/jointScreening";
import {getUserBranchConnectionData} from "http/branch-screening";
import {startLoading, loadingSuccess} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {logout} from "redux/auth/actions";
import analytics from "services/analytics";
import {setActiveBranchId} from "redux/branches/selector";

import {formatBeforeSubmit, formatInactiveUser} from "./helpers";
import {
  getUsersWithoutRpStateIds,
  setInactiveUsersLoading,
  setJRentersConnectionStatus,
  updateJRentersConnectionStatus,
  formatInactiveUsers,
} from "./helpers/inactiveJSConnectionStatus";
import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    jointScreeningRequest: startLoading,
    jointScreeningFailure: handlers.getJointScreeningFailure,
    jointScreeningSuccess: loadingSuccess,

    getJointScreeningSuccess: handlers.getJointScreeningSuccess,
    createJointScreeningSuccess: handlers.getJointScreeningSuccess,
    updateJointScreeningSuccess: handlers.getJointScreeningSuccess,

    getInactiveJRentersConnectionStatusRequest: handlers.getInactiveJRentersConnectionStatusRequest,
    getInactiveJRentersConnectionStatusSuccess: handlers.getInactiveJRentersConnectionStatusSuccess,
    getInactiveJRentersConnectionStatusFailure: handlers.getInactiveJRentersConnectionStatusFailure,

    setDateFinalisedAt: handlers.setDateFinalisedAt,

    clear: handlers.clear,
  },
  extraReducers: {
    [logout]: handlers.clear,
  },
  initialState,
  name: "jointScreeningDetails",
});

const showFormError = error => ({[FORM_ERROR]: extractError(error)});

export const getInactiveJRentersConnectionStatus = ({inactiveIds, branchId}) => async (
  dispatch,
  getState,
) => {
  const allRenters = getState().jointScreeningDetails.data.jointRenters;
  const usersWithLoading = setInactiveUsersLoading({isLoading: true, inactiveIds, allRenters});

  dispatch(actions.getInactiveJRentersConnectionStatusRequest(usersWithLoading));

  try {
    const data = await getUsersConnectionStatuses({branchId, usersIds: inactiveIds});

    dispatch(
      actions.getInactiveJRentersConnectionStatusSuccess(
        setJRentersConnectionStatus({
          inactiveIds,
          inactiveUsers: formatInactiveUsers(data),
          allRenters: usersWithLoading,
        }),
      ),
    );
  } catch (error) {
    dispatch(
      actions.getInactiveJRentersConnectionStatusFailure({
        jointRenters: setInactiveUsersLoading({
          isLoading: false,
          inactiveIds,
          allRenters: usersWithLoading,
        }),
        error,
      }),
    );
  }
};

export const setFinalisedAtDate = () => async dispatch => {
  dispatch(actions.setDateFinalisedAt());
};

export const getJointScreeningById = ({id, withBranchConnections}) => async (
  dispatch,
  getState,
) => {
  const {statuses} = getState().locales.translations;
  const {lastSuccessfulBranchId} = getState().jointScreeningDetails;
  let updatedLastSuccessfulBranchId = lastSuccessfulBranchId;

  try {
    const rentersArray = [];

    dispatch(actions.jointScreeningRequest());
    const apiFunc = withBranchConnections ? getDetailsBranchConnections : getDetails;

    const data = await apiFunc({id}).then(formatInactiveUser({translations: statuses}));

    dispatch(setActiveBranchId(data.branchId));

    if (data.jointRenters?.length > 0) {
      const rentersBranchConnectionData = data.jointRenters.reduce((acc, renterData) => {
        if (renterData.userId) {
          acc.push(
            getUserBranchConnectionData({
              userId: renterData.userId,
              branchId: data.branchId,
              screeningType: "RENTER",
            }),
          );
        }

        return acc;
      }, []);

      const rentersBranchConnections = await Promise.all(rentersBranchConnectionData);

      data.jointRenters.forEach(item => {
        const renterBranchConnection =
          item.userId && rentersBranchConnections.length
            ? rentersBranchConnections.find(
                branchConnection => branchConnection.renterId === item.userId,
              )
            : {};

        rentersArray.push({
          ...item,
          branchConnectionId: renterBranchConnection.id,
        });
      });
    }

    const inactiveUsersIds = getUsersWithoutRpStateIds(data.jointRenters);

    if (data?.branchId) {
      updatedLastSuccessfulBranchId = data.branchId;
    }

    dispatch(
      actions.getJointScreeningSuccess({
        data: {...data, jointRenters: rentersArray},
        lastSuccessfulBranchId: updatedLastSuccessfulBranchId,
      }),
    );

    if (inactiveUsersIds.length !== 0) {
      dispatch(
        getInactiveJRentersConnectionStatus({
          inactiveIds: inactiveUsersIds,
          branchId: updatedLastSuccessfulBranchId,
        }),
      );
    }
  } catch (error) {
    dispatch(actions.jointScreeningFailure(extractError(error.type)));
  }
};

export const createJointScreening = ({afterSuccess, logComplete, values}) => async dispatch => {
  try {
    dispatch(actions.jointScreeningRequest());

    const formattedData = formatBeforeSubmit(values);

    const data = await create(formattedData);

    dispatch(actions.createJointScreeningSuccess({data}));
    afterSuccess(data);
    logComplete();

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));

    return {[FORM_ERROR]: error};
  }
};

export const deleteJointScreening = ({id}) => async dispatch => {
  try {
    await remove({id});

    dispatch(actions.jointScreeningSuccess());

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));

    return {[FORM_ERROR]: error};
  }
};

export const updateJointScreening = ({id, logComplete, values}) => async dispatch => {
  try {
    const formattedData = formatBeforeSubmit(values, true);

    await update({values: formattedData, id});

    dispatch(actions.jointScreeningSuccess());
    logComplete();

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));

    return {[FORM_ERROR]: error};
  }
};

export const deleteRenterFromJointScreening = ({renterId, screeningID}) => async (
  dispatch,
  getState,
) => {
  try {
    const {data} = getState().jointScreeningDetails;

    const updateJointRenters = data.jointRenters.filter(renter => renter.id !== renterId);

    await removeRenter({renterId, screeningID});

    dispatch(
      actions.updateJointScreeningSuccess({data: {...data, jointRenters: updateJointRenters}}),
    );

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));

    // send error into AutoSave component
    throw error;
  }
};

const getRenterData = ({values, field}) => {
  const pattern = ".rentShareAmount";

  const index = field.indexOf(pattern);

  const neededPath = field.slice(0, index);

  return get(values, neededPath);
};

export const updateRenterFromJointScreening = ({screeningID, values, field}) => async dispatch => {
  try {
    const {id, rentShareAmount} = getRenterData({values, field});

    await updateRenter({renterId: id, screeningID, data: {rentShareAmount: +rentShareAmount}});

    dispatch(actions.jointScreeningSuccess());

    return null;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));

    // send error into AutoSave component
    throw error;
  }
};

export const addRenterToJointScreening = ({screeningID, user}) => async (dispatch, getState) => {
  try {
    const {data} = getState().jointScreeningDetails;
    const apiData = {email: user.email};

    if (user.isInvitedUser) {
      apiData.connectionInviteId = user.id;
    } else {
      apiData.userId = user.userId || user.id;
    }

    const renter = await addRenter({data: apiData, screeningID});

    const updateJointRenters = [...data.jointRenters, renter];

    dispatch(
      actions.updateJointScreeningSuccess({data: {...data, jointRenters: updateJointRenters}}),
    );

    return renter;
  } catch (e) {
    const error = extractError(e);

    dispatch(actions.jointScreeningFailure(error));
    throw error;
  }
};

export const downloadPDFReport = ({id, pdfType}) => async (_, getState) => {
  const {generateReport} = getState().locales.translations.modules.rentPassport.detailsPage;
  const {activeBranchId} = getState().branchSelector;

  try {
    DownloadToaster.show({title: generateReport});
    const page = window.open("", "_self");
    const response = await downloadPDF({id, pdfType, branchId: activeBranchId});

    DownloadToaster.dismiss();
    page.location.href = response;
  } catch (error) {
    DownloadToaster.dismiss();
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error)});
  }
};

export const resendInviteToRenter = ({agentInviteId, branchId, afterSuccess}) => async () => {
  try {
    await resendInvite({
      branchId,
      agentInviteId,
    });

    if (afterSuccess) {
      afterSuccess();
    }

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const successUpdateAfterReInvite = ({renterId}) => async (dispatch, getState) => {
  const allRenters = getState().jointScreeningDetails.data.jointRenters;

  dispatch(
    actions.getInactiveJRentersConnectionStatusSuccess(
      updateJRentersConnectionStatus({renterId, allRenters}),
    ),
  );
};

export const {clear} = actions;

export default reducer;
