import {http} from "http/index";
import qs from "utils/query";

import {formatUsersList, formatInvitedUsersList, formatUserDetails} from "./formatters";

export const getList = async ({branchId}) =>
  http.get(`/company/agents${qs.stringify({branchId})}`).then(formatUsersList);

export const getInvitedList = async ({branchId}) =>
  http.get(`/company/invited-agents${qs.stringify({branchId})}`).then(formatInvitedUsersList);

export const invite = async userData => http.post("/agency/agent/invite", userData);

export const details = async agentId =>
  http.get(`/company/agents/${agentId}`).then(formatUserDetails);

export const update = async userData => http.post("/agency/agent/update", userData);

export const cancelInvite = async id => http.delete(`/agency/agent/invite/${id}`);

export const resendInvite = async id => http.post(`/agency/agent/invite/${id}/resend`);

export const setUserActivity = async ({agentId, state}) =>
  http.post(`/agency/agent/${agentId}`, {state});

export const editUser = async ({agentId, firstName, lastName}) =>
  http.patch(`/agency/agent/${agentId}`, {firstName, lastName});
