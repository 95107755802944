import {createSlice} from "@reduxjs/toolkit";
import {FORM_ERROR} from "final-form";

import {invite, details, update} from "http/users";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {getBranches} from "redux/branches/list";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    startUserDetailsLoading: startLoading,
    setUserDetailsError: loadingFailed,

    inviteUserSuccess: handlers.submitSuccess,

    getUserDetailsSuccess: handlers.getUserDetailsSuccess,

    updateUserSuccess: handlers.submitSuccess,

    clearSubmitDetail: handlers.clearSubmitDetail,
    clearUserDetails: handlers.clear,
  },
  extraReducers: {
    [logout]: handlers.clear,
  },
  initialState,
  name: "userDetails",
});

export const sendInviteToUser = userData => async () => {
  try {
    await invite(userData);

    return undefined;
  } catch (e) {
    const error = extractError(e);

    return {[FORM_ERROR]: error};
  }
};

export const getUserDetails = ({agencyId, userId}) => async dispatch => {
  try {
    dispatch(actions.startUserDetailsLoading());

    const data = await details(userId);

    dispatch(getBranches(agencyId));
    dispatch(actions.getUserDetailsSuccess(data));
  } catch (error) {
    dispatch(actions.setUserDetailsError(extractError(error)));
  }
};

export const updateUser = ({userData, callAfterSubmit}) => async dispatch => {
  try {
    const data = await update(userData);

    dispatch(actions.updateUserSuccess(data));
    callAfterSubmit();
  } catch (error) {
    dispatch(actions.setUserDetailsError(extractError(error)));
  }
};

export const clearSubmitDetail = () => async dispatch => {
  dispatch(actions.clearSubmitDetail());
};

export const clearUserDetails = () => async dispatch => {
  dispatch(actions.clearUserDetails());
};

export default reducer;
