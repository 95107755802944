import {format, parseISO, getTime, isValid} from "date-fns";

export const DATE_FORMAT = "dd / MM / yyyy";
export const DATE_FORMAT_SHORT = "dd/MM/yyyy";
export const DATE_FORMAT_MONTH = "MM / yyyy";
export const DATE_TIME_FORMAT = "dd / MM / yyyy h:mm aa";
export const FULL_MONTH_FORMAT = "dd MMM yyyy";
export const TIME_FORMAT = "h:mm aa";

export const getCurrentDate = ({dateFormat = DATE_FORMAT} = {}) =>
  format(new Date(), dateFormat || DATE_FORMAT);

export const formatDate = ({date, dateFormat = DATE_FORMAT, options = {}, fallback}) => {
  const parsedDate = parseISO(date);

  return isValid(parsedDate) ? format(parsedDate, dateFormat, options) : fallback;
};

export const formatDateNewLineTime = ({date, fallback = "-"}) =>
  `${formatDate({date, fallback})}\n${formatDate({
    date,
    fallback,
    dateFormat: TIME_FORMAT,
  })}`;

export const formatDayAndMonth = value => {
  const dayOrMonth = parseInt(value, 10);

  return `0${dayOrMonth}`.slice(-2);
};

export const parseAndGetTime = date => getTime(parseISO(date));

export const formatDOBFromObjToString = ({dayOfBirth, yearOfBirth, monthOfBirth}) =>
  `${formatDayAndMonth(dayOfBirth)} / ${formatDayAndMonth(monthOfBirth)} / ${yearOfBirth}`;
