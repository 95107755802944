import {http} from "..";

const PREFIX = "/connections";

export const requestRentPassport = async values => http.post(`${PREFIX}/send-agent-invite`, values);

export const requestRPGuarantor = async values =>
  http.post(`${PREFIX}/guarantor/send-agent-invite`, values);

// TODO: Remove with old rp page
export const requestFullScreening = async values => http.post(`/full-reference-request`, values);

export const requestFullScreeningBranchConnection = async branchConnectionId =>
  http.post(`/full-reference-request/branch-connection/${branchConnectionId}`);

// TODO: Remove this when old screening is disabled
export const requestFullScreeningFromGuarantorNoConnection = async ({
  branchId,
  guarantorId,
  renterId,
}) =>
  http.post(`/agent/guarantor-request/request-full-referencing`, {branchId, guarantorId, renterId});

export const changeTenancyStatusRequest = async values =>
  http.post(`/agent/connections/tenancy-status`, values);

export const changeBranchConnectionsTenancyStatusRequest = async values =>
  http.post(`/agent/branch-connections/tenancy-status`, values);

export const getIsGuarantor = async renterId =>
  http.get(`/agent${PREFIX}/is-guarantor?userId=${renterId}`);
