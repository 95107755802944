import React from "react";
import {string, func, node} from "prop-types";

import {Wrapper, Logo, Container, Content, Footer, Action} from "./styled";

const ErrorWrapper = ({children, onActionClick, actionType, actionText}) => (
  <Wrapper>
    <Logo />
    <Container>
      <Content>{children}</Content>
      <Footer>
        <Action text={actionText} onClick={onActionClick} type={actionType} />
      </Footer>
    </Container>
  </Wrapper>
);

ErrorWrapper.propTypes = {
  children: node.isRequired,
  actionType: string,
  actionText: string,
  onActionClick: func,
};

ErrorWrapper.defaultProps = {
  actionType: "secondary",
  actionText: "",
  onActionClick: () => {},
};

export default ErrorWrapper;
