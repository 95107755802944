// eslint-disable-next-line import/no-cycle
import {http} from "..";

import {formatUser} from "./formatters";

const PREFIX = "/agent/auth";

export const login = ({email, password}) =>
  http.post(`${PREFIX}/login/email`, {email, password}).then(formatUser);

export const loginGhost = token => http.post(`${PREFIX}/login/ghost`, {token}).then(formatUser);

export const logout = () => http.post(`${PREFIX}/logout`);

export const logoutGhost = token => http.post(`${PREFIX}/logout/ghost`, {token});

export const refreshAuthenticationToken = data => http.post(`${PREFIX}/refresh-token`, data);

export const register = values => http.post(`${PREFIX}/register/email`, values);

export const requestResetPassword = ({email}) =>
  http.post(`${PREFIX}/request-password-reset`, {email});

export const resetPassword = ({password, token}) =>
  http.post(`${PREFIX}/confirm-password-reset`, {password, token});

export const verifyEmail = ({token}) => http.post(`${PREFIX}/activate`, {token});
