const initialState = {
  availableBranches: [],
  isLoading: false,
  isIdVerificationEnabled: false,
  error: "",
};

export const getBranchesForRPInviteSuccess = (state, {payload}) => ({
  ...state,
  availableBranches: payload,
  isLoading: false,
});

export const clearAvailableBranchesData = state => ({
  ...initialState,
  isIdVerificationEnabled: state.isIdVerificationEnabled,
});

export const getIsIdentityVerificationEnableSuccess = (state, {payload}) => ({
  ...state,
  isIdVerificationEnabled: payload,
  isLoading: false,
});

export const clearState = state => ({
  ...initialState,
  isIdVerificationEnabled: state.isIdVerificationEnabled,
});

export default initialState;
