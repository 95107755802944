const initialState = {
  email: "",
  isRequested: false,
  isRecovered: false,
  isLoading: false,
  error: "",
};

export const recoverSuccess = (state, {payload}) => ({
  ...state,
  email: payload.email,
  isRequested: true,
  isLoading: false,
  error: "",
});

export const resetRequest = () => ({...initialState});

export const recoverProcessingSuccess = state => ({
  ...state,
  isRecovered: true,
  isLoading: false,
  error: "",
});

export default initialState;
