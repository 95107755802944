import {formatDate} from "utils/date";
import {formatPayment} from "utils/currency";

export const formatJointScreening = data =>
  data.map(item => ({
    date: formatDate({date: item.createdAt}),
    name: item.name,
    tenants: item.numberOfJointRenters,
    amount: formatPayment({
      amount: item.rentShareAmount,
      digits: false,
    }),
    frequency: item.paymentFrequency,
  }));
