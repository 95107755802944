export const userStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  INVITED: "INVITED",
};

export const userType = {
  AGENT: "AGENT",
  ADMIN: "ADMIN",
};

export const iconConfig = {
  [userStatus.INACTIVE]: {
    iconName: "close-circle-fill",
    type: "default",
    textKey: "inactive",
    isPrimary: true,
  },
  [userStatus.ACTIVE]: {
    iconName: "tick-circle",
    type: "success",
    isPrimary: true,
    textKey: "active",
  },
};

export const USER_GROUPS = {
  ADMIN: "ADMIN",
  AGENT: "AGENT",
  RENTER: "RENTER",
};
