export const addressToString = (address, fields) => {
  const addressString = (fields || ["line1", "line2", "line3", "town", "postCode", "countryCode"])
    .reduce((accumulator, current) => [...accumulator, address[current]], [])
    .filter(Boolean)
    .join(", ");

  return addressString;
};

export const addressFieldsFormatter = address => {
  const addressLines = ["line1", "line2", "line3"];
  const defaultBranchAddress = [
    "flat",
    "houseName",
    "houseNumber",
    "street",
    "town",
    "countryCode",
    "postCode",
  ];
  const addressWithLines = ["line1", "line2", "line3", "town", "postCode", "countryCode"];

  const withLines = Object.keys(address).some(key => addressLines.includes(key) && address[key]);

  if (withLines) {
    return addressWithLines;
  }

  return defaultBranchAddress;
};

export const formLine1 = (address = {}) => {
  if (address.flat) {
    return address.flat.match(/(Flat)/gi) ? address.flat : `Flat ${address.flat}`;
  }

  if (address.houseName) {
    return address.houseName;
  }

  if (address.houseNumber && address.street) {
    return `${address.houseNumber} ${address.street}`;
  }

  return "";
};

export const formLine2 = (address = {}) => {
  if (address.flat) {
    if (address.houseName) {
      return address.houseName;
    }

    if (address.houseNumber && address.street) {
      return `${address.houseNumber} ${address.street}`;
    }
  }

  if (address.houseName && address.houseNumber && address.street) {
    return `${address.houseNumber} ${address.street}`;
  }

  return "";
};

export const formLine3 = (address = {}) => {
  if (address.flat && address.houseName && address.houseNumber && address.street) {
    return `${address.houseNumber} ${address.street}`;
  }

  return "";
};

export const addressLineFormatter = address => ({
  ...address,
  line1: formLine1(address),
  line2: formLine2(address),
  line3: formLine3(address),
});
