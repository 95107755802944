import {createSlice} from "@reduxjs/toolkit";
import {FORM_ERROR} from "final-form";

import * as http from "http/branches";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {addressLineFormatter} from "utils/addresses";
import analytics from "services/analytics";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    createBranchRequest: startLoading,
    createBranchesSuccess: handlers.createBranchSuccess,
    createBranchesFailure: loadingFailed,
    getEditableBranchSuccess: handlers.getEditableBranchSuccess,
    clearState: handlers.clearState,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "createBranch",
});

const showFormError = error => ({[FORM_ERROR]: extractError(error)});

export const create = ({values, branchId, pathname}) => async (dispatch, getState) => {
  const {id} = getState().auth.profile.agency;

  try {
    dispatch(actions.createBranchRequest());
    let data = {};
    const {address} = values;
    const formattedAddress = addressLineFormatter(address);

    if (branchId) {
      data = await http.edit({...values, address: formattedAddress}, branchId);
    } else {
      data = await http.create({...values, address: formattedAddress, agencyId: id});
    }
    analytics.logEvent(branchId ? "agentEditBranchComplete" : "agentAddBranchComplete", {
      pathname,
    });

    dispatch(actions.createBranchesSuccess(data));

    return null;
  } catch (error) {
    const err = extractError(error);

    dispatch(actions.createBranchesFailure(err));
    analytics.logEvent(branchId ? "agentEditBranchFailure" : "agentAddBranchFailure", {
      pathname,
      error: err,
    });

    return showFormError(error);
  }
};

export const getBranchInfo = branchId => async dispatch => {
  try {
    const data = await http.getBranchInfo({branchId, forEdit: true});

    dispatch(actions.getEditableBranchSuccess(data));

    return null;
  } catch (error) {
    return showFormError(error);
  }
};

export const clearState = () => dispatch => {
  dispatch(actions.clearState());
};

export default reducer;
