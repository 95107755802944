import MapWithDefault from "utils/map";

const BACKEND_STATUSES = {
  PENDING: "PENDING",
  DONE: "DONE",
};

const STATUSES = {
  IN_PROGRESS: "IN_PROGRESS",
  CONSIDER: "CONSIDER",
  COMPLETE: "COMPLETE",
};

const STATUSES_MAPPING = {
  [BACKEND_STATUSES.PENDING]: STATUSES.IN_PROGRESS,
  [BACKEND_STATUSES.DONE]: STATUSES.COMPLETE,
};

const ICON_CONFIG = {
  [STATUSES.IN_PROGRESS]: {
    iconName: "progress",
    type: "default",
    textKey: "inProgress",
  },
  [STATUSES.COMPLETE]: {
    iconName: "tick-circle",
    type: "info",
    textKey: "complete",
  },
  [STATUSES.CONSIDER]: {
    iconName: "tick-circle",
    type: "warning",
    textKey: "consider",
  },
};

const RENT_FREQUENCY = {
  WEEKLY: "WEEKLY",
  MONTHLY: "MONTHLY",
};

const iconConfig = new MapWithDefault(
  ICON_CONFIG[STATUSES.IN_PROGRESS],
  Object.entries(ICON_CONFIG),
);

export const connectionInviteStatuses = {
  CONNECTION_INVITE_PENDING: "CONNECTION_INVITE_PENDING",
  CONNECTION_INVITE_DECLINED: "CONNECTION_INVITE_DECLINED",
  CONNECTION_ACTIVE: "CONNECTION_ACTIVE",
  CONNECTION_DECLINED: "CONNECTION_DECLINED",
};

const statuses = new MapWithDefault(STATUSES.IN_PROGRESS, Object.entries(STATUSES));

const rentFrequency = new MapWithDefault(RENT_FREQUENCY.MONTHLY, Object.entries(RENT_FREQUENCY));

const statusesMapping = new MapWithDefault(
  STATUSES_MAPPING.PENDING,
  Object.entries(STATUSES_MAPPING),
);

export {iconConfig, statuses, rentFrequency, statusesMapping};
