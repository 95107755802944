import {createSlice} from "@reduxjs/toolkit";
import {Toaster, DownloadToaster} from "@findyourcanopy/canopy-ui";

import {extractError} from "redux/helpers";
import {downloadPDF, downloadPDFNewRef} from "http/rentPassport";
import {logout} from "redux/auth/actions";
import analytics from "services/analytics";

import initialState, * as handlers from "./handlers";

export const {actions, reducer} = createSlice({
  reducers: {
    clearPassportData: handlers.clearPassportData,
  },
  extraReducers: {
    [logout]: handlers.clearPassportData,
  },
  initialState,
  name: "rentPassportDetails",
});

export const clearPassportData = () => dispatch => {
  dispatch(actions.clearPassportData());
};

export const downloadPDFReport = ({
  id,
  isGuarantor,
  pdfType,
  afterSuccess,
  isNewRefServise,
}) => async (_, getState) => {
  const {generateReport} = getState().locales.translations.modules.rentPassport.detailsPage;
  const {activeBranchId} = getState().branchSelector;
  const api = isNewRefServise ? downloadPDFNewRef : downloadPDF;

  try {
    DownloadToaster.show({title: generateReport});
    const page = window.open("", "_self");
    const response = await api({id, isGuarantor, pdfType, branchId: activeBranchId});

    if (afterSuccess) {
      afterSuccess();
    }

    DownloadToaster.dismiss();
    page.location.href = response;
  } catch (error) {
    DownloadToaster.dismiss();
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error)});
  }
};

export default reducer;
