export const MINIMUM_FETCH_INTERVAL = 12 * 60 * 60 * 1000; // 12 hours

export const FETCH_TIME = 60 * 1000; // 1 minute

export const LAST_FETCH_STATUSES = {
  FAILURE: "failure",
  NO_FETCH_YET: "no_fetch_yet",
  SUCCESS: "success",
  THROTTLED: "throttled",
};

/* Remote config parameters start */

// Offerings
export const LANDLORD_INSURANCE_REFERRAL_LINK = "landlord_insurance_referral_link";

// Rent Passport Details
export const EMPLOYMENT_REFERENCE_DETAILS_VISIBLE = "employment_reference_details_visible";
export const LANDLORD_REFERENCE_DETAILS_VISIBLE = "landlord_reference_details_visible";

// Request Rent Passport
export const IS_IDENTITY_VERIFICATION_ENABLED = "is_identity_verification_enabled";
export const MOVE_IN_DATE_NOTIFICATION_THRESHOLD = "move_in_date_notification_threshold";
export const WHITELISTED_IDV_AGENCIES = "whitelisted_idv_agencies";

// Verification documents
export const IDV_DOCUMENTS_VISIBLE = "idv_documents_visible";
export const INCOME_DOCUMENTS_VISIBLE = "income_documents_visible";
export const SAVINGS_DOCUMENTS_VISIBLE = "savings_documents_visible";

/* Remote config parameters end */

export const DEFAULTS = {
  [EMPLOYMENT_REFERENCE_DETAILS_VISIBLE]: "false",
  [IDV_DOCUMENTS_VISIBLE]: "false",
  [INCOME_DOCUMENTS_VISIBLE]: "false",
  [IS_IDENTITY_VERIFICATION_ENABLED]: "false",
  [LANDLORD_INSURANCE_REFERRAL_LINK]: "true",
  [LANDLORD_REFERENCE_DETAILS_VISIBLE]: "false",
  [MOVE_IN_DATE_NOTIFICATION_THRESHOLD]: 3,
  [SAVINGS_DOCUMENTS_VISIBLE]: "false",
  [WHITELISTED_IDV_AGENCIES]: "",
};
