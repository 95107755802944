/* eslint-disable no-param-reassign */
export const startLoading = state => {
  state.isLoading = true;
};

export const loadingFailed = (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
};

export const loadingSuccess = state => {
  state.isLoading = false;
};

export const setSubmittedSuccessValue = (state, action) => ({
  ...state,
  isSubmittedSuccess: action.payload,
});
