export const overviewFormatter = ({
  agents,
  branches,
  rentPassports,
  tenancies,
  showOnTheMarketLogo,
}) => ({
  branches,
  rentPassports: {
    complete: rentPassports.completed,
    inProgress: rentPassports.inProgress,
    value: rentPassports.completed + rentPassports.inProgress,
  },
  tenancies,
  showOnTheMarketLogo,
  users: agents,
});
