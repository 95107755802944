import {formatDate} from "utils/date";
import {statuses} from "constants/rentPassport";
import {guarantorInfoTypes} from "constants/guarantors";
import {formatPayment} from "utils/currency";
import {formatPersonalData} from "redux/helpers/rentPassportHelpers";

export const formatGuaranteedRenters = ({count, items}) => {
  let data = [];

  data = items.map(({user, createdAt, submittedAt}) => {
    const userData = formatPersonalData({user});
    const common = {
      affordability: formatPayment({
        amount: user.monthlyRentAffordability,
        digits: false,
      }),
      ...userData,
    };

    const config = {
      [statuses.NOT_STARTED]: {
        type: guarantorInfoTypes.notStarted,
        actionDate: formatDate({date: createdAt}),
        ...userData,
      },
      [statuses.IN_PROGRESS]: {
        type: guarantorInfoTypes.in_progress,
        actionDate: formatDate({date: submittedAt}),
        ...userData,
      },
      [statuses.CONSIDER]: {type: guarantorInfoTypes.consider, ...common},
      [statuses.VERIFY]: {type: guarantorInfoTypes.verify, ...common},
      [statuses.HIGH_RISK]: {type: guarantorInfoTypes.highRisk, ...common},
    };

    return config[user.state] || {};
  });

  return {
    count,
    data,
  };
};
