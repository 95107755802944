class Canceller {
  constructor() {
    this.tokens = [];
  }

  static getInstance() {
    if (!Canceller.instance) {
      Canceller.instance = new Canceller();
    }

    return Canceller.instance;
  }

  setCancelToken = cancel => {
    this.tokens.push(cancel);
  };

  cancelAll = () => {
    this.tokens.forEach(cancel => {
      cancel();
    });

    this.tokens = [];
  };
}

export default Canceller.getInstance();
