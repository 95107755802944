import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Text} from "@findyourcanopy/canopy-ui";

import ErrorWrapper from "components/ErrorWrapper";

import {StatusHeading, Description} from "./styled";

const ServerUnavailable = () => {
  const {push} = useHistory();
  const {title, text, tryAgain} = useSelector(
    ({locales}) => locales.translations.modules.errorPage.serverUnavailable,
  );

  const goToHome = useCallback(() => push("/"), [push]);

  return (
    <ErrorWrapper onActionClick={goToHome} actionType="primary" actionText={tryAgain}>
      <StatusHeading align="center">503</StatusHeading>
      <Text.Heading align="center" primary>
        {title}
      </Text.Heading>
      <Description align="center">{text}</Description>
    </ErrorWrapper>
  );
};

export default ServerUnavailable;
