/* eslint-disable no-console */
import {loadEmbed, loadSpace} from "@usersnap/browser";

import packageJson from "../../../package.json";

const APP_INFO = {
  app: "Canopy HQ",
  appVersion: packageJson.version,
  environment: process.env.REACT_APP_APP_STAGE,
};

// FIXME: Nonce should be unique per http request
const NONCE = "N8C9/pJVtmF1nzpV0mYhvA==";

class UserSnap {
  #api;

  #initialized = false;

  #loaded = false;

  constructor() {
    loadSpace(process.env.REACT_APP_USERSNAP_GLOBAL_API_KEY, NONCE)
      .then(api => {
        this.#api = api;
        this.#loaded = true;
      })
      .catch(error => {
        console.error("[UserSnap]: Failed to load service", error);
      });
  }

  get initialized() {
    return this.#initialized;
  }

  // For init options see https://help.usersnap.com/reference/global-api#params
  initialize = async (options = {}, onSuccess = null, onError = null) => {
    try {
      if (this.#loaded) {
        const {custom, ...rest} = options;

        await this.#api.init({
          custom: {
            ...APP_INFO,
            ...custom,
          },
          ...rest,
        });

        this.#initialized = true;

        if (onSuccess) {
          onSuccess();
        }
      } else {
        setTimeout(() => {
          this.initialize(options, onSuccess, onError);
        }, 1000);
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to initialize service", error);

      if (onError) {
        onError(error);
      }
    }
  };

  destroy = async () => {
    try {
      if (this.#initialized) {
        await this.#api.destroy();

        this.#initialized = false;
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to destroy service", error);
    }
  };

  show = async projectApiKey => {
    try {
      if (this.#initialized) {
        await this.#api.show(projectApiKey);
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to show project", error);
    }
  };

  hide = async projectApiKey => {
    try {
      if (this.#initialized) {
        await this.#api.hide(projectApiKey);
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to hide project", error);
    }
  };

  logEvent = async eventName => {
    try {
      if (this.#initialized) {
        await this.#api.logEvent(eventName);
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to log event", error);
    }
  };

  // For widget events see https://help.usersnap.com/reference/global-api#widget-events
  on = async (eventName, callback) => {
    try {
      if (this.#initialized) {
        this.#api.on(eventName, callback);

        return () => {
          this.off(eventName, callback);
        };
      }

      return undefined;
    } catch (error) {
      console.error("[UserSnap]: Failed to register widget event handler", error);

      return undefined;
    }
  };

  off = async (eventName, callback) => {
    try {
      if (this.#initialized) {
        this.#api.off(eventName, callback);
      }
    } catch (error) {
      console.error("[UserSnap]: Failed to remove widget event handler", error);
    }
  };

  // For init options and API see https://help.usersnap.com/reference/website-embed-api
  initializeEmbed = async (projectApiKey, targetNode, options = {}, onSuccess, onError) => {
    try {
      const embedApi = await loadEmbed(projectApiKey, NONCE);

      const {custom, ...rest} = options;

      await embedApi.init({
        custom: {
          ...APP_INFO,
          ...custom,
        },
        mountNode: targetNode,
        ...rest,
      });

      if (onSuccess) {
        onSuccess();
      }

      return embedApi;
    } catch (error) {
      console.error("[UserSnap]: Failed to initialize embed", error);

      if (onError) {
        onError(error);
      }

      return undefined;
    }
  };
}

export default new UserSnap();
