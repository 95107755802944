import {getPerformance, trace} from "firebase/performance";

import {initializeFirebaseApp} from "services/firebase";

class PerformanceMonitoring {
  #performance;

  constructor() {
    const app = initializeFirebaseApp();

    this.#performance = getPerformance(app);
  }

  /**
   * Creates an uninitialized instance of {@link firebase.performance.Trace `trace`} and returns
   * it.
   *
   * @param traceName The name of the trace instance.
   * @return The Trace instance.
   */
  trace = traceName => trace(this.#performance, traceName);
}

export default new PerformanceMonitoring();
