import {format} from "date-fns";

import {FULL_MONTH_FORMAT} from "utils/date";

export const formatData = data => ({
  responseData: data.id,
  date: format(new Date(), FULL_MONTH_FORMAT),
  itemId: data.itemId,
});

export const prepareInitialData = data =>
  data.map(file => ({
    file: {
      ...file,
      name: file.decodeFileName,
    },
    abortFunc: () => {},
    date: format(file.updatedAt, FULL_MONTH_FORMAT),
    isLoading: false,
    progress: 100,
    withoutProgressBar: true,
    responseData: file.documentRefId,
    uploadedToTempStorage: true,
  }));
