import {createSlice} from "@reduxjs/toolkit";

import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    setIsUserSnapInitialized: handlers.setIsUserSnapInitialized,
  },
  extraReducers: {
    [logout]: handlers.resetSlice,
  },
  initialState,
  name: "app",
});

export const {setIsUserSnapInitialized} = actions;

export default reducer;
