import MapWithDefault from "utils/map";

const screeningStatuses = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  CONSIDER: "CONSIDER",
  ACCEPT: "ACCEPT",
  RENT_READY: "RENT_READY",
  HIGH_RISK: "HIGH_RISK",
  VERIFY: "VERIFY", // its replacement for accept. in future accept can be removed when we fully migrate to verify
};

export const successScreeningStatuses = [screeningStatuses.ACCEPT, screeningStatuses.VERIFY];

const statusesForRentPassportScreeningType = {
  INSTANT: "INSTANT",
  FULL: "FULL",
  PRIORITY: "PRIORITY",
  RIGHT_TO_RENT: "RIGHT_TO_RENT",
  PRE_QUALIFICATION: "PRE_QUALIFICATION",
};

const rpSources = {
  MANUAL_RENTER: "MANUAL_RENTER",
  MANUAL_AGENT: "MANUAL_AGENT",
  MANUAL_ADMIN: "MANUAL_ADMIN",
  GHOST_ADMIN: "GHOST_ADMIN",
  BRANCH_DEEPLINK: "BRANCH_DEEPLINK",
  RENT_READY_API: "RENT_READY_API",
  REFERENCE_API: "REFERENCE_API",
  HQ_INVITE_FILTER: "HQ_INVITE_FILTER", // NOTE: Used for filter on branch screening list oneOf([MANUAL_AGENT, MANUAL_ADMIN, GHOST_ADMIN])
};

const iconNamesForRentPassport = {
  [screeningStatuses.NOT_STARTED]: "not-started-status",
  [screeningStatuses.IN_PROGRESS]: "loop-status",
  [screeningStatuses.ACCEPT]: "accept-status",
  [screeningStatuses.VERIFY]: "accept-status",
  [screeningStatuses.RENT_READY]: "accept-status",
  [screeningStatuses.CONSIDER]: "accept-status",
  [screeningStatuses.HIGH_RISK]: "attention-status",
};

const iconNamesForOtherPages = {
  [screeningStatuses.NOT_STARTED]: "dots-horizontal",
  [screeningStatuses.IN_PROGRESS]: "progress",
  [screeningStatuses.ACCEPT]: "tick-circle",
  [screeningStatuses.CONSIDER]: "tick-circle",
  [screeningStatuses.RENT_READY]: "tick-circle",
  [screeningStatuses.HIGH_RISK]: "attention-fill",
  [screeningStatuses.VERIFY]: "tick-circle",
};

export const screeningStatusData = {
  [screeningStatuses.NOT_STARTED]: {
    type: "default",
    textKey: "notStarted",
    isPrimary: true,
  },
  [screeningStatuses.IN_PROGRESS]: {
    type: "default",
    textKey: "inProgress",
    isPrimary: true,
  },
  [screeningStatuses.ACCEPT]: {
    type: "success",
    textKey: "accept",
    isPrimary: true,
  },
  [screeningStatuses.VERIFY]: {
    type: "success",
    textKey: "accept",
    isPrimary: true,
  },
  [screeningStatuses.RENT_READY]: {
    type: "success",
    iconName: "accept",
    textKey: "rentReady",
  },
  [screeningStatuses.CONSIDER]: {
    type: "success",
    textKey: "accept",
    bottomIconType: "warning",
    bottomIconName: "tick-circle",
    bottomNoteKey: "withConsideration",
    isPrimary: true,
  },
  [screeningStatuses.HIGH_RISK]: {
    type: "error",
    textKey: "highRisk",
    isPrimary: true,
  },
};

export const overallPreQualifiedStatusConfig = {
  [screeningStatuses.NOT_STARTED]: {
    type: "default",
    iconName: "not-started-status",
    textKey: "notStarted",
  },
  [screeningStatuses.IN_PROGRESS]: {
    type: "default",
    iconName: "loop-status",
    textKey: "inProgress",
  },
  [screeningStatuses.ACCEPT]: {
    type: "success",
    iconName: "accept-status",
    textKey: "rentReady",
  },
  [screeningStatuses.VERIFY]: {
    type: "success",
    iconName: "accept-status",
    textKey: "rentReady",
  },
  [screeningStatuses.RENT_READY]: {
    type: "success",
    iconName: "accept-status",
    textKey: "rentReady",
  },
  [screeningStatuses.CONSIDER]: {
    type: "success",
    iconName: "accept-status",
    textKey: "rentReady",
    bottomIconType: "warning",
    bottomIconName: "tick-circle",
    bottomNoteKey: "withConsideration",
  },
  [screeningStatuses.HIGH_RISK]: {
    type: "error",
    iconName: "attention-status",
    textKey: "highRisk",
  },
};

const setupConfig = ({basic, icons}) => {
  let temp = {};

  Object.keys(basic).forEach(key => {
    temp = {
      ...temp,
      [key]: {
        ...basic[key],
        iconName: icons[key],
      },
    };
  });

  return temp;
};

const iconConfigForPassportPageAndReport = setupConfig({
  basic: screeningStatusData,
  icons: iconNamesForRentPassport,
});

const iconConfigForOtherPages = setupConfig({
  basic: screeningStatusData,
  icons: iconNamesForOtherPages,
});

const iconConfig = new MapWithDefault(
  iconConfigForOtherPages[screeningStatuses.IN_PROGRESS],
  Object.entries(iconConfigForOtherPages),
);

const iconConfigForPassport = new MapWithDefault(
  iconConfigForPassportPageAndReport[screeningStatuses.IN_PROGRESS],
  Object.entries(iconConfigForPassportPageAndReport),
);

const iconConfigPreQualifiedForPassport = new MapWithDefault(
  overallPreQualifiedStatusConfig[screeningStatuses.IN_PROGRESS],
  Object.entries(overallPreQualifiedStatusConfig),
);

export {
  screeningStatuses,
  iconConfig,
  statusesForRentPassportScreeningType,
  iconConfigForPassport,
  rpSources,
  iconConfigPreQualifiedForPassport,
};
