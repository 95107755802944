const initialState = {
  isLoading: true,
  error: {
    message: "",
    title: undefined,
    status: null,
  },
  guaranteedRenters: [],
};

export const clearPassportData = () => ({...initialState});

export default initialState;
