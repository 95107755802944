const initialState = {
  data: {
    list: [],
    total: 0,
  },
  isLoading: false,
  error: "",
};

export const getRentPassportListSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const getRentPassportListRequest = state => ({
  ...state,
  data: initialState.data,
  isLoading: true,
});

export const resendInviteSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const tenancyStatusChangeSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const toggleFavorite = (state, {payload}) => {
  const updatedList = state.data.list.map(item => {
    const copyItem = {...item};

    if (copyItem.id === payload.id || copyItem.renterId === payload.id) {
      copyItem.favorites = !copyItem.favorites;
    }

    return copyItem;
  });

  return {
    ...state,
    data: {
      ...state.data,
      list: [...updatedList],
    },
  };
};

export const removeFavorite = (state, {payload}) => {
  const updatedList = state.data.list.filter(
    item => item.id !== payload.id || item.renterId !== payload.id,
  );

  return {
    ...state,
    data: {
      total: updatedList.length,
      list: [...updatedList],
    },
  };
};

export const clearState = () => ({...initialState});

export default initialState;
