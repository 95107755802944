import {createSlice} from "@reduxjs/toolkit";
import {FORM_ERROR} from "final-form";
import {isAfter} from "date-fns";

import {accountUserType} from "constants/accountSetup";
import {agencyCheck, agencyCreate} from "http/agency";
import {login, loginGhost, logout, logoutGhost, refreshAuthenticationToken} from "http/auth";
import {getBranches} from "redux/branches/selector";
import {requestVerificationProviderInformation} from "redux/company";
import {extractError} from "redux/helpers";
import analytics from "services/analytics";
import {addressLineFormatter} from "utils/addresses";
import {DATE_TIME_FORMAT, formatDate} from "utils/date";

import {
  setToken,
  setRefreshToken,
  unsetToken,
  unsetRefreshToken,
  setGhostId,
  unsetGhostId,
} from "../../http";

import {logout as commonLogout} from "./actions";
import initialState, * as handlers from "./handlers";

export const {actions, reducer} = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticateFailure: handlers.authenticateFailure,
    authenticateRequest: handlers.authenticateRequest,
    authenticateSuccess: handlers.authenticateSuccess,

    authenticateGhostFailure: handlers.authenticateGhostFailure,
    authenticateGhostRequest: handlers.authenticateGhostRequest,
    authenticateGhostSuccess: handlers.authenticateGhostSuccess,

    ghostLogoutSuccess: handlers.ghostLogoutSuccess,
    logoutFailure: handlers.logoutFailure,
    logoutSuccess: handlers.logoutSuccess,

    createAgencyFailure: handlers.createAgencyFailure,
    createAgencyRequest: handlers.createAgencyRequest,
    createAgencySuccess: handlers.createAgencySuccess,
  },
  extraReducers: {
    [commonLogout]: handlers.clearAuthState,
  },
});

const clearAuthenticationData = (dispatch, reason = "") => {
  unsetToken();
  unsetGhostId();
  unsetRefreshToken();

  analytics.setUserId(null);

  localStorage.removeItem("loginData");

  dispatch(commonLogout({error: reason}));
};

export const authenticate = values => async (dispatch, getState) => {
  try {
    clearAuthenticationData(dispatch);

    dispatch(actions.authenticateRequest());

    const data = await login(values);

    localStorage.setItem("loginData", JSON.stringify(data));

    setToken(data.token);
    setRefreshToken(data.refreshToken);

    analytics.setUserId(data.user.id);

    if (data.agent) {
      analytics.setUserProperties({agentRole: data.agent.role});
    } else {
      analytics.setUserProperties({userType: data.user.type});
    }

    dispatch(actions.authenticateSuccess(data));

    analytics.logEvent("agentAccountLoginSuccess");

    dispatch(getBranches());
    dispatch(requestVerificationProviderInformation());

    return undefined;
  } catch (error) {
    const {errors} = getState().locales.translations;

    let errorMessage = extractError(error);
    let errorCode = error?.code || "unknown";

    if (error?.status === 504 || errorMessage === errors.networkError) {
      analytics.logEvent("loginFailedUnexpectedError");
      errorMessage = errors.loginFailedUnexpectedError;
    }

    if (error?.code === "invalid-credentials" || errorMessage === errors.invalidCredentials) {
      errorMessage = errors.invalidCredentials;
      errorCode = error.code;
    }

    if (error?.code === "account-locked") {
      const till = formatDate({
        date: error?.blockedTill || "",
        dateFormat: DATE_TIME_FORMAT,
        fallback: "unknown",
      });

      errorMessage = `${errors.accountLocked} ${till}.`;
      errorCode = error.code;
    }

    analytics.logEvent("agentAccountLoginFailure", {error: errorMessage});

    dispatch(actions.authenticateFailure({code: errorCode, message: errorMessage}));

    return {[FORM_ERROR]: errorMessage};
  }
};

export const requestLogout = onSuccess => async dispatch => {
  try {
    await logout();

    clearAuthenticationData(dispatch);

    dispatch(actions.logoutSuccess());

    analytics.logEvent("agentAccountLogout");

    if (onSuccess) {
      onSuccess();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(actions.logoutFailure());
  }
};

export const authenticateToken = (token, afterSuccess) => async dispatch => {
  try {
    clearAuthenticationData(dispatch);

    dispatch(actions.authenticateGhostRequest(token));

    const data = await loginGhost(token);

    setToken(data.token);
    setGhostId(data.ghostId);
    setRefreshToken(data.refreshToken);

    localStorage.setItem("loginData", JSON.stringify(data));

    analytics.setUserId(data.ghostId);

    if (data.agent) {
      analytics.setUserProperties({agentRole: data.agent.role});
    } else {
      analytics.setUserProperties({userType: data.user.type});
    }

    dispatch(actions.authenticateGhostSuccess(data));

    analytics.logEvent("agentAccountLoginSuccess");

    dispatch(getBranches());

    if (afterSuccess) {
      afterSuccess(data);
    }
  } catch (error) {
    const errorText = extractError(error);

    dispatch(actions.authenticateGhostFailure(errorText));
  }
};

export const requestLogoutGhost = () => async (dispatch, getState) => {
  try {
    const {token} = getState().auth.ghostModeLogin;

    await logoutGhost(token);

    clearAuthenticationData(dispatch);

    dispatch(actions.ghostLogoutSuccess());

    analytics.logEvent("agentAccountLogout");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    dispatch(actions.logoutFailure());
  }
};

export const restoreAuthentication = () => async (dispatch, getState) => {
  try {
    const {profile} = getState().auth;
    const {reason} = getState().locales.translations.modules.errorPage.unauthorized;

    let data = localStorage.getItem("loginData");

    if (!data) {
      clearAuthenticationData(dispatch, reason);

      return undefined;
    }

    data = JSON.parse(data);

    if (profile && profile.agent && data.agent && profile.agent.userId !== data.agent.userId) {
      window.location.reload();
    }

    setToken(data.token);
    setGhostId(data.ghostId);
    setRefreshToken(data.refreshToken);

    if (!isAfter(new Date(data.expires * 1000), new Date())) {
      try {
        const loginData = await refreshAuthenticationToken({refreshToken: data.refreshToken});

        analytics.logEvent("warningSessionExpired");

        if (loginData) {
          loginData.ghostId = data.ghostId;
        }

        setToken(loginData.token);
        setGhostId(data.ghostId);
        setRefreshToken(loginData.refreshToken);

        localStorage.setItem("loginData", JSON.stringify(loginData));
      } catch (error) {
        clearAuthenticationData(dispatch, reason);

        return undefined;
      }
    }

    analytics.setUserId(data.ghostId ? data.ghostId : data.user.id);

    dispatch(actions.authenticateSuccess(data));

    return data;
  } catch (error) {
    clearAuthenticationData(dispatch);

    return undefined;
  }
};

const saveAgentData = async values => {
  const loginDataRaw = localStorage.getItem("loginData");

  if (loginDataRaw) {
    const loginData = JSON.parse(loginDataRaw);
    const {agency, agent} = values;
    const newLoginData = {
      ...loginData,
      agency,
      agent,
    };

    await localStorage.setItem("loginData", JSON.stringify(newLoginData));
  }
};

export const createAgent = (values, userType, translations) => async dispatch => {
  try {
    dispatch(actions.createAgencyRequest());

    if (userType === accountUserType.COMPANY) {
      const {companyName, companyPhone, registrationNumber, agentId, address} = values;
      let isAvailable = true;

      if (registrationNumber) {
        const checkResult = await agencyCheck({registrationNumber});

        isAvailable = checkResult?.isAvailable;
      }

      if (isAvailable) {
        const data = await agencyCreate({
          name: companyName,
          companyRegistrationNumber: registrationNumber,
          phone: companyPhone,
          agentId,
          address: addressLineFormatter(address),
          type: userType,
        });

        await saveAgentData(data);

        analytics.logEvent("agentAccountCreationComplete");

        return dispatch(actions.createAgencySuccess(data));
      }

      dispatch(
        actions.createAgencyFailure({message: translations.errorCompanyRegNumberUnAvailable}),
      );

      return {[FORM_ERROR]: translations.errorCompanyRegNumberUnAvailable};
    }

    const data = await agencyCreate({...values, type: userType});

    await saveAgentData(data);
    analytics.logEvent("agentAccountCreationComplete");
    dispatch(getBranches());

    return dispatch(actions.createAgencySuccess(data));
  } catch (error) {
    return dispatch(actions.createAgencyFailure({message: extractError(error)}));
  }
};

export default reducer;
