const initialState = {
  data: [],
  isLoading: false,
  error: "",
};

export const getBranchListSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
  error: "",
});

export const successUpdateBranches = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const clearState = () => ({...initialState});

export default initialState;
