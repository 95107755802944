export const types = {
  landlord: "landlord",
  employer: "employer",
};

export const statuses = {
  PENDING: "PENDING",
  ACCEPTED: "ACCEPTED",
  DECLINED: "DECLINED",
  DECLINED_BY_REFEREE: "DECLINED_BY_REFEREE",
  NOT_STARTED: "NOT_STARTED",
  NOT_RENT: "NOT_RENT",
  IN_PROGRESS: "IN_PROGRESS",
  VERIFIED: "VERIFIED",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  STUDENT: "STUDENT",
  RETIRED: "RETIRED",
  REJECTED: "REJECTED",
  UNEMPLOYED: "UNEMPLOYED",
  CAN_NOT_PROVIDE: "CAN_NOT_PROVIDE",
  NOT_REQUESTED: "NOT_REQUESTED",
  NO: "NO",
  FAIL: "FAIL",
  DONE: "DONE",
  HOMEOWNER: "HOMEOWNER",
  NOT_HOMEOWNER: "NOT_HOMEOWNER",
  CONTRACT: "CONTRACT",
};

export const steps = {
  USER: "USER",
  REFEREE: "REFEREE",
  VERIFICATION: "VERIFICATION",
  ADMIN_NOTE: "ADMIN_NOTE",
};

// TODO this object contains an unused statuses. Should be refactored
export const referenceTagData = {
  [statuses.PENDING]: {
    type: "default",
    icon: "progress",
    textKey: "inProgress",
  },
  [statuses.ACCEPTED]: {
    type: "success",
    icon: "tick-circle",
    textKey: "verified",
  },
  [statuses.DECLINED_BY_REFEREE]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "rejected",
  },
  [statuses.DECLINED]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToVerify",
  },
  [statuses.NOT_STARTED]: {
    type: "default",
    textKey: "notStarted",
  },
  [statuses.NOT_RENT]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "notRented",
  },
  [statuses.VERIFIED]: {
    type: "success",
    icon: "tick",
    textKey: "complete",
  },
  [statuses.IN_PROGRESS]: {
    type: "default",
    icon: "progress",
    textKey: "inProgress",
  },
  [statuses.CAN_NOT_PROVIDE]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.SELF_EMPLOYED]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.STUDENT]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.RETIRED]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.UNEMPLOYED]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.NO]: {
    type: "default",
    icon: null,
    textKey: "notStarted",
  },
  [statuses.FAIL]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToProvide",
  },
  [statuses.DONE]: {
    type: "success",
    icon: "tick",
    textKey: "complete",
  },
  [statuses.HOMEOWNER]: {
    type: "success",
    icon: "tick",
    textKey: "complete",
  },
  [statuses.NOT_REQUESTED]: {
    type: "default",
    icon: null,
    textKey: "notRequested",
  },
  [statuses.NOT_HOMEOWNER]: {
    type: "warning",
    iconName: "attention-fill",
    textKey: "guarantorIsNotHomeowner",
  },
  [statuses.CONTRACT]: {
    type: "warning",
    iconName: "attention-fill",
    textKey: "contract",
  },
};

export const employerReferenceTagData = {
  [statuses.PENDING]: {
    type: "default",
    icon: "progress",
    textKey: "inProgress",
  },
  [statuses.ACCEPTED]: {
    type: "success",
    icon: "tick-circle",
    textKey: "verified",
  },
  [statuses.DECLINED_BY_REFEREE]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "rejected",
  },
  [statuses.DECLINED]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToVerify",
  },
  [statuses.NOT_STARTED]: {
    type: "default",
    textKey: "notStarted",
  },
  [statuses.NOT_RENT]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "notRented",
  },
  [statuses.VERIFIED]: {
    type: "success",
    icon: "tick",
    textKey: "complete",
  },
  [statuses.IN_PROGRESS]: {
    type: "default",
    icon: "progress",
    textKey: "inProgress",
  },
  [statuses.CAN_NOT_PROVIDE]: {
    type: "success",
    icon: "tick-circle",
    textKey: "notApplicable",
  },
  [statuses.SELF_EMPLOYED]: {
    type: "success",
    icon: "tick-circle",
    textKey: "notApplicable",
  },
  [statuses.STUDENT]: {
    type: "success",
    icon: "tick-circle",
    textKey: "notApplicable",
  },
  [statuses.RETIRED]: {
    type: "success",
    icon: "tick-circle",
    textKey: "notApplicable",
  },
  [statuses.UNEMPLOYED]: {
    type: "success",
    icon: "tick-circle",
    textKey: "notApplicable",
  },
  [statuses.NO]: {
    type: "default",
    icon: null,
    textKey: "notStarted",
  },
  [statuses.FAIL]: {
    type: "warning",
    icon: "attention-fill",
    textKey: "unableToVerify",
  },
  [statuses.DONE]: {
    type: "success",
    icon: "tick",
    textKey: "complete",
  },
  [statuses.NOT_REQUESTED]: {
    type: "default",
    icon: null,
    textKey: "notRequested",
  },
  [statuses.NOT_HOMEOWNER]: {
    type: "warning",
    iconName: "attention-fill",
    textKey: "guarantorIsNotHomeowner",
  },
  [statuses.CONTRACT]: {
    type: "warning",
    iconName: "attention-fill",
    textKey: "contract",
  },
};

export const employmentTypes = {
  contract: "Contract",
  permanent: "Permanent",
};

export const followUpsTypes = {
  AUTOMATIC: "AUTOMATIC",
  MANUAL: "MANUAL",
};
