import styled from "styled-components";
import {Flex, utils} from "@findyourcanopy/canopy-ui";

const Container = styled(Flex.Column)`
  margin: 0 auto;
  max-width: ${({maxWidth = 890}) => maxWidth}px;
  padding: ${({pb = 35, pl = 30, pr = 30, pt = 35}) => `${pt}px ${pr}px ${pb}px ${pl}px`};
  width: 100%;

  ${utils.responsive.mobileL} {
    padding: 23px 20px;
  }
`;

const RowContainer = styled(Container)`
  flex-flow: row nowrap;
`;

const RowToColumnContainer = styled(RowContainer)`
  ${utils.responsive.mobileL} {
    flex-flow: column nowrap;
  }
`;

const PaymentContainer = styled(Flex.Row)`
  align-items: baseline;
`;

const FullPageContainer = styled(Flex.Column)`
  background-color: ${({theme}) => theme.primaryAccent};
  height: 100%;
`;

export {Container, FullPageContainer, PaymentContainer, RowContainer, RowToColumnContainer};
