const formatBeforeSubmit = ({
  name,
  newAddress,
  branchId,
  internalCode,
  paymentFrequency,
  rentAmount,
}) => ({
  name,
  branchId,
  rentAmount: +rentAmount,
  paymentFrequency,
  internalCode,
  newAddress,
});

export default formatBeforeSubmit;
