import React, {useCallback} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {Text} from "@findyourcanopy/canopy-ui";

import ErrorWrapper from "components/ErrorWrapper";

import {StatusHeading, Description} from "./styled";

const NotFound = () => {
  const history = useHistory();
  const goToHome = useCallback(() => history.push("/"), []); // eslint-disable-line react-hooks/exhaustive-deps
  const {actionText, text, description} = useSelector(
    ({locales}) => locales.translations.modules.errorPage.notFound,
  );

  return (
    <ErrorWrapper onActionClick={goToHome} actionType="primary" actionText={actionText}>
      <StatusHeading align="center">404</StatusHeading>
      <Text.Heading align="center" primary>
        {text}
      </Text.Heading>
      <Description align="center">{description}</Description>
    </ErrorWrapper>
  );
};

export default NotFound;
