import cloneDeep from "lodash/cloneDeep";

class MapWithDefault extends Map {
  get(key) {
    if (!this.has(key)) {
      return this.default;
    }

    return super.get(key);
  }

  constructor(defaultValue, entries) {
    super(entries);
    this.default = defaultValue;
  }

  clone() {
    return cloneDeep(this);
  }
}

export default MapWithDefault;
