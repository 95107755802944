export const formatUserType = ({list, total, labels}) => ({
  total,
  list: list.map(el => ({
    ...el,
    userType: labels[el.userType],
  })),
});

export const changeResendInviteId = (data, {oldId, newId}) => {
  const formattedValues = [...data];
  let changedItem;

  formattedValues.map((item, index) => {
    if (item.id === oldId) {
      changedItem = {...item};
      changedItem.id = newId;
      formattedValues[index] = changedItem;
    }

    return null;
  });

  return formattedValues;
};
