import React from "react";
import PropTypes from "prop-types";
import {Route, Redirect, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

const PrivateRoute = ({component: Component, redirectTo, ...rest}) => {
  const {isAuthenticated} = useSelector(({auth}) => auth);
  const {reason} = useSelector(({locales}) => locales.translations.modules.errorPage.unauthorized);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props => {
        if (isAuthenticated) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: redirectTo,
              state: {
                from: location,
                reason,
              },
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  redirectTo: PropTypes.string,
};

PrivateRoute.defaultProps = {
  redirectTo: "/auth/login",
};

export default PrivateRoute;
