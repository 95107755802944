import update from "immutability-helper";

import {prepareInitialData} from "./helpers";

const initialState = {
  data: [],
  error: "",
  isLoading: false,
};

export const uploadFileRequest = (state, {payload}) => {
  const id = payload.file.name.replace(/\./g, "");

  const file = {...payload.file, id};

  return {...state, error: "", data: [...state.data, {...payload, file}]};
};

export const uploadFileSuccess = (state, {payload}) => {
  const {fileName, date, responseData} = payload;
  const index = state.data.findIndex(({file}) => file.name === fileName);
  const file = {...state.data[index].file, id: payload.itemId};

  return update(state, {
    data: {
      [index]: {
        date: {$set: date},
        responseData: {$set: responseData},
        progress: {$set: 100},
        isLoading: {$set: false},
        file: {$set: file},
      },
    },
  });
};

export const uploadFileFailure = (state, {payload}) => {
  const {message, fileName} = payload;

  return {
    ...state,
    error: message,
    data: state.data.filter(({file}) => file.name !== fileName),
  };
};

export const uploadedToTempStorage = (state, {payload}) => {
  const fileName = payload;
  const index = state.data.findIndex(({file}) => file.name === fileName);

  return update(state, {
    data: {
      [index]: {
        uploadedToTempStorage: {$set: true},
      },
    },
  });
};

export const updateProgressValues = (state, {payload}) => {
  const {fileName, progress} = payload;
  const index = state.data.findIndex(({file}) => file.name === fileName);

  return {...state, data: update(state.data, {[index]: {progress: {$set: progress}}})};
};

export const removeFile = (state, {payload}) => ({
  ...state,
  data: state.data.filter(({file}) => file.name !== payload),
});

export const setInitialDocuments = (state, {payload}) => {
  const initialData = prepareInitialData(payload);

  return {...state, data: initialData};
};

export const clearState = () => ({...initialState});

export default initialState;
