import {emailPreferencesTypes} from "constants/notificationPreferences";

const initialState = {
  data: {
    emailNotificationsEnabled: false,
    preferences: {
      [emailPreferencesTypes.INSTANT_RP_COMPLETE]: false,
      [emailPreferencesTypes.EMPLOYER_REF_COMPLETE]: false,
      [emailPreferencesTypes.LANDLORD_REF_COMPLETE]: false,
      [emailPreferencesTypes.GUARANTOR_RP_COMPLETE]: false,
      [emailPreferencesTypes.RTR_READY_FOR_REVIEW]: false,
    },
  },
  isLoading: true,
  error: "",
};

export const getNotificationSettingsSuccess = (state, {payload}) => ({
  ...state,
  data: payload,
  isLoading: false,
});

export const clearState = () => ({...initialState});

export default initialState;
