import {isNumber} from "lodash";

import makeFullName from "utils/makeFullName";
import {formatPayment} from "utils/currency";
import {rentFrequency} from "constants/jointScreening";

export const formatRenter = ({user, ...el}) => {
  let guarantor;

  if (!user) {
    return {
      ...el,
      isActiveUser: false,
      rentShareAmount: el.rentShareAmount.toString(),
    };
  }

  if (user.guarantor) {
    const {
      state,
      firstName,
      lastName,
      rentAffordability,
      monthlyRentAffordability,
    } = user.guarantor;

    guarantor = {
      state,
      fullName: makeFullName({firstName, lastName}),
      formattedName: firstName[0] + lastName[0],
      rentAffordability: formatPayment({amount: rentAffordability, digits: false}),
      monthlyRentAffordability: isNumber(monthlyRentAffordability)
        ? formatPayment({amount: Math.round(monthlyRentAffordability), digits: false})
        : null,
    };
  }

  return {
    ...el,
    isActiveUser: true,
    rentShareAmount: el.rentShareAmount.toString(),
    fullName: makeFullName(user),
    formattedName: user.firstName[0] + user.lastName[0],
    userId: user.id,
    isRPCompleted: user.isRPCompleted,
    guarantor,
    rentAffordability: user.rentAffordability,
    status: user.rpState,
  };
};

export const formatDetails = data => ({
  ...data,
  // combinedRentAffordability: formatPayment({amount: data.combinedRentAffordability, digits: false}),
  isWeeklyFrequency: data.paymentFrequency === rentFrequency.get("WEEKLY"),
  jointRenters: data.jointRenters.map(formatRenter),
});

export const formatJointScreeningRenters = users =>
  users.map(({firstName, lastName, ...props}) => ({
    label: props.userId,
    value: {
      fullName: makeFullName({firstName, lastName}),
      formattedName: firstName[0] + lastName[0],
      ...props,
    },
  }));
