const initialState = {
  error: "",
  errorCode: "",
  ghostModeLogin: {
    error: "",
    isRequestSent: false,
    loading: true,
    token: "",
  },
  isAuthChecked: false,
  isAuthenticated: false,
  isGhostModeAuthenticated: false,
  isLoading: false,
  profile: null,
};

export const clearAuthState = (_, {payload}) => ({
  ...initialState,
  error: payload?.error || initialState.error,
  isAuthChecked: true,
});

export const authenticateFailure = (state, {payload}) => ({
  ...state,
  error: payload.message,
  errorCode: payload.code,
  isLoading: false,
});
export const authenticateRequest = () => ({
  ...initialState,
  isAuthChecked: true,
  isLoading: true,
});
export const authenticateSuccess = (state, {payload}) => ({
  ...state,
  isAuthChecked: true,
  isAuthenticated: true,
  isGhostModeAuthenticated: !!payload.ghostId,
  isLoading: false,
  profile: payload,
});

export const authenticateGhostFailure = (state, {payload}) => ({
  ...state,
  ghostModeLogin: {
    ...state.ghostModeLogin,
    error: payload,
    loading: false,
  },
});
export const authenticateGhostRequest = (state, {payload}) => ({
  ...state,
  error: "",
  errorCode: "",
  ghostModeLogin: {
    ...state.ghostModeLogin,
    isRequestSent: true,
    token: payload,
  },
  isAuthChecked: true,
});
export const authenticateGhostSuccess = (state, {payload}) => ({
  ...state,
  ghostModeLogin: {
    ...state.ghostModeLogin,
    loading: false,
  },
  isAuthenticated: true,
  isGhostModeAuthenticated: true,
  isLoading: false,
  profile: payload,
});

export const ghostLogoutSuccess = () => ({
  ...initialState,
  isAuthChecked: true,
});
export const logoutFailure = state => ({
  ...state,
  isAuthChecked: true,
});
export const logoutSuccess = () => ({
  ...initialState,
  isAuthChecked: true,
});

export const createAgencyFailure = (state, {payload}) => ({
  ...state,
  error: payload.message,
  isLoading: false,
});
export const createAgencyRequest = state => ({
  ...state,
  error: "",
  isLoading: true,
});
export const createAgencySuccess = (state, {payload}) => ({
  ...state,
  isLoading: false,
  profile: {
    ...state.profile,
    ...payload,
  },
});

export default initialState;
