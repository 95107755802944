export const setVerifyEmailError = (errorStatus, translations) => {
  switch (errorStatus) {
    case 404:
      return translations.wrongTokenText;

    case 409:
      return translations.expiredTokenText;

    default:
      return translations.failText;
  }
};
