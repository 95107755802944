import debounce from "lodash/debounce";

const innerFunction = debounce(
  ({dispatchProps, args, action}) => action(...args)(...dispatchProps),
  1500,
  {leading: true, trailing: false},
);

export const debouncedAction = action => (...args) => (...dispatchProps) =>
  innerFunction({dispatchProps, args, action});
