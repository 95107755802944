import qs from "utils/query";

import {http} from "..";

import {invitation} from "./formatters";

export const agencyCheck = data => http.post("/agency/check-agency-number-availability", data);

export const agencyCreate = data => http.post("/agency/create/v2", data);

export const getAgencyProducts = async () => http.get("/agent/agency-products");

export const getProductBranchMapping = async (productId, vendorId) =>
  http.get(`/agent/agency-products/product/${productId}/vendor/${vendorId}/branch-mapping`);

export const getInvitationAgentInfo = id => http.get(`/agency/agent/invite/${id}`).then(invitation);

export const getInvitationAgentInfoByType = ({inviteId, inviteType}) =>
  http.get(`/user/invite-details${qs.stringify({inviteId, inviteType})}`);

export const registerAgentByInvite = data => http.post("/agency/agent/acceptInvite", data);
