import {http} from "http/index";
import qs from "utils/query";

import {formatAddress, formatLookupAddress} from "./formatters";

export const lookupAddressByPostcode = async (postCode, countryCode = "GB") =>
  http.post("/lookup-address", {address: {postCode, countryCode}}).then(formatAddress);

export const lookupAddress = async postCode =>
  http
    .get(`/agent/joint-screening/address-search${qs.stringify({postCode})}`)
    .then(formatLookupAddress);
