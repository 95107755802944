import {useState, useCallback} from "react";

const key = "isDarkMode";

const useDarkMode = () => {
  const stored = localStorage.getItem(key);
  const [isDarkMode, enableDarkMode] = useState(stored === "true");
  const toggleDarkMode = useCallback(() => {
    localStorage.setItem(key, `${!isDarkMode}`);
    enableDarkMode(!isDarkMode);
  }, [isDarkMode]);

  return {isDarkMode, toggleDarkMode};
};

export default useDarkMode;
