const currencyCodeToSymbolMap = {
  GBP: "£",
  EUR: "€",
  USD: "$",
  BGN: "лв",
  CHF: "CHF",
  CZK: "Kč",
  DKK: "kr",
  HRK: "kn",
  HUF: "ft",
  PLN: "zł",
  RUB: "₽",
  RON: "lei",
  TRY: "₺",
  UAH: "₴",
};

export const currencyCodes = Object.keys(currencyCodeToSymbolMap);

export const currencyCodeToSymbol = code => currencyCodeToSymbolMap[code] || code;

export const formatPayment = ({amount = 0, currency = "GBP", digits = true}) =>
  Math.abs(amount).toLocaleString("en-UK", {
    style: "currency",
    currency,
    minimumFractionDigits: digits ? 2 : 0,
    maximumFractionDigits: 2,
  });
