import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";

import rootReducer from "./reducers";

export default function configureAppStore(preloadedState) {
  return configureStore({
    reducer: rootReducer,
    middleware: [...getDefaultMiddleware()],
    preloadedState,
    enhancers: [],
    devTools: true,
  });
}
