export const REFERENCING_GROUPS = {
  BASIC: "BASIC",
  EXTENDED: "EXTENDED",
  OPTIONAL: "OPTIONAL",
};

export const REFERENCING_SECTION_TYPES = {
  CREDIT_CHECK: "CREDIT_CHECK",
  EMPLOYEE_REFERENCE: "EMPLOYEE_REFERENCE",
  GUARANTORS: "GUARANTORS",
  IDENTITY_VERIFICATION: "IDENTITY_VERIFICATION",
  INCOME: "INCOME",
  LANDLORD_REFERENCE: "LANDLORD_REFERENCE",
  RENT: "RENT",
  RIGHT_TO_RENT: "RIGHT_TO_RENT",
  SAVINGS: "SAVINGS",
};

export const REFERENCING_SECTION_STATUSES = {
  CONSIDER: "CONSIDER",
  HIGH_RISK: "HIGH_RISK",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_STARTED: "NOT_STARTED",
  RENT_READY: "RENT_READY",
  SELF_CERTIFIED: "SELF_CERTIFIED",
  VERIFY: "VERIFY",
};

export const WARNING_RULE_STATES = {
  CONSIDER: "CONSIDER",
  HIGH_RISK: "HIGH_RISK",
};

export const fieldNameToSectionKey = {
  identity: "IDENTITY",
  financialSummary: "FINANCIAL_HISTORY",
  "livingSituation.homeowner": "HOMEOWNER",
};

export const SCREENING_TYPE = {
  RENTER: "RENTER",
  GUARANTOR: "GUARANTOR",
  INDIRECT_GUARANTOR: "INDIRECT_GUARANTOR",
};

export const WARNINGS_CONSIDER_SECTIONS_KEYS = {
  IDENTITY: "IDENTITY",
  ID_VERIFICATION: "ID_VERIFICATION", // Duplicate old rp
  IDENTITY_VERIFICATION: "IDENTITY_VERIFICATION", // Duplicate new rp
  ADDRESS_HISTORY: "ADDRESS_HISTORY",
  CREDIT_INDICATOR: "CREDIT_INDICATOR",
  FINANCIAL_HISTORY: "FINANCIAL_HISTORY",
  ACTIVE_CCJ: "ACTIVE_CCJ",
  SATISFIED_CCJ: "SATISFIED_CCJ",
  INSOLVENCY: "INSOLVENCY",
  INCOME: "INCOME",
  RENT: "RENT",
  EMPLOYMENT_REFERENCE: "EMPLOYMENT_REFERENCE", // Dublicate old rp
  EMPLOYEE_REFERENCE: "EMPLOYEE_REFERENCE", // Dublicate new rp
  LANDLORD_REFERENCE: "LANDLORD_REFERENCE",
  HOMEOWNER: "HOMEOWNER",
  UK_RESIDENT: "UK_RESIDENT",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  OVERALL: "OVERALL",
};

export const WARNINGS_HIGH_RISK_SECTIONS_KEYS = {
  IDENTITY: "IDENTITY",
  ID_VERIFICATION: "ID_VERIFICATION",
  ADDRESS_HISTORY: "ADDRESS_HISTORY",
  CREDIT_INDICATOR: "CREDIT_INDICATOR",
  FINANCIAL_HISTORY: "FINANCIAL_HISTORY",
  ACTIVE_CCJ: "ACTIVE_CCJ",
  SATISFIED_CCJ: "SATISFIED_CCJ",
  INSOLVENCY: "INSOLVENCY",
  INCOME: "INCOME",
  RENT: "RENT",
  EMPLOYMENT_REFERENCE: "EMPLOYMENT_REFERENCE",
  LANDLORD_REFERENCE: "LANDLORD_REFERENCE",
  HOMEOWNER: "HOMEOWNER",
  UK_RESIDENT: "UK_RESIDENT",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  OVERALL: "OVERALL",
};

export const WARNING_MESSAGE_KEYS = {
  AGE_UNDER_18: "AGE_UNDER_18",
  UNABLE_TO_VERIFY: "UNABLE_TO_VERIFY",
  NOT_FOUND: "NOT_FOUND",
  NOT_RENTED: "NOT_RENTED",
  HIGH_RISK: "HIGH_RISK",
  VERY_POOR: "VERY_POOR",
  ZERO_VERIFIED_ADDRESSES: "ZERO_VERIFIED_ADDRESSES",
  PRESENT: "PRESENT",
  IVA: "IVA",
  BANKRUPTCY: "BANKRUPTCY",
  IVA_AND_BANKRUPTCY: "IVA_AND_BANKRUPTCY",
  PRESENT_OVER_300: "PRESENT_OVER_300",
  REJECTED: "REJECTED",
  UNABLE_TO_PROVIDE: "UNABLE_TO_PROVIDE",
  NEGATIVE_FEEDBACK: "NEGATIVE_FEEDBACK",
  WOULD_NOT_RENT_TO_THIS_PERSON_AGAIN: "WOULD_NOT_RENT_TO_THIS_PERSON_AGAIN",
  MULTIPLE_ISSUES: "MULTIPLE_ISSUES",
  NOT_HOMEOWNER: "NOT_HOMEOWNER",
  NO_PHOTO_ID: "NO_PHOTO_ID",
  HOMEOWNER: "HOMEOWNER",
  NOT_UK_RESIDENT: "NOT_UK_RESIDENT",
  CONTRACT: "CONTRACT",
  REQUEST_REJECTED: "REQUEST_REJECTED",
  NO_INCOME: "NO_INCOME",
  SELF_EMPLOYED: "SELF_EMPLOYED",
  LOW_MONTH_EVIDENCE: "LOW_MONTH_EVIDENCE",
  ZERO_HOUR_CONTRACT: "ZERO_HOUR_CONTRACT",
  BLOCKED_FRAUD_STATUS: "BLOCKED_FRAUD_STATUS",
  CONTRACT_UNDER_6_MONTHS: "CONTRACT_UNDER_6_MONTHS",
};

export const creditCheckWarningsRuleCods = {
  "credit-check-default-notlinuk": "credit-check-default-notlinuk",
  "credit-check-default-rule-notukres": "credit-check-default-rule-notukres",
  "credit-check-default-error": "credit-check-default-error",
  "credit-check-default-rejected": "credit-check-default-rejected",
  "credit-check-default-rule-iau18": "credit-check-default-rule-iau18",
  "credit-check-default-rule-vpoor": "credit-check-default-rule-vpoor",
  "credit-check-default-iva": "credit-check-default-iva",
  "credit-check-default-bnkr": "credit-check-default-bnkr",
  "credit-check-default-ivaabnkr": "credit-check-default-ivaabnkr",
  "credit-check-default-rule-haccj": "credit-check-default-rule-haccj",
  "credit-check-letmojo-rule-h2accj": "credit-check-letmojo-rule-h2accj",
  "credit-check-letmojo-rule-hbigaccj": "credit-check-letmojo-rule-hbigaccj",
  "credit-check-letmojo-rule-hlowaccj": "credit-check-letmojo-rule-hlowaccj",
  "credit-check-default-rule-hbigsccj": "credit-check-default-rule-hbigsccj",
  "credit-check-default-rule-hlowsccj": "credit-check-default-rule-hlowsccj",
  "credit-check-letmojo-rule-hsccj": "credit-check-letmojo-rule-hsccj",
  "credit-check-letmojo-guarantor-rule-hsccj": "credit-check-letmojo-guarantor-rule-hsccj",
  "credit-check-guarantor-rule-haccj": "credit-check-guarantor-rule-haccj",
  "credit-check-guarantor-rule-hbigsccj": "credit-check-guarantor-rule-hbigsccj",
  "credit-check-guarantor-rule-hlowsccj": "credit-check-guarantor-rule-hlowsccj",
  "credit-check-letmojo-rule-vpoor": "credit-check-letmojo-rule-vpoor",
  "credit-check-guarantor-iva": "credit-check-guarantor-iva",
  "credit-check-guarantor-bnkr": "credit-check-guarantor-bnkr",
  "credit-check-guarantor-ivaabnkr": "credit-check-guarantor-ivaabnkr",
  "credit-check-guarantor-rejected": "credit-check-guarantor-rejected",
  "credit-check-guarantor-error": "credit-check-guarantor-error",
  "credit-check-guarantor-notlinuk": "credit-check-guarantor-notlinuk",
  "credit-check-guarantor-rule-notukres": "credit-check-guarantor-rule-notukres",
  "credit-check-letmojo-guarantor-rule-vpoor": "credit-check-letmojo-guarantor-rule-vpoor",
};
