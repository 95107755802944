export default {
  CTA: {},
  errors: {},
  modules: {
    auth: {},
    rentPassport: {},
    branches: {},
    users: {},
    company: {},
  },
};
