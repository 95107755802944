import {createSlice} from "@reduxjs/toolkit";

import {getRequests} from "http/rightToRent";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import {extractError} from "redux/helpers";
import {logout} from "redux/auth/actions";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  reducers: {
    getRightToRentListRequest: startLoading,
    getRightToRentListSuccess: handlers.getRightToRentListSuccess,
    getRightToRentListFailure: loadingFailed,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
  initialState,
  name: "rightToRentList",
});

export const getRightToRentList = type => async dispatch => {
  try {
    dispatch(actions.getRightToRentListRequest());
    const data = await getRequests(type);

    dispatch(actions.getRightToRentListSuccess(data));
  } catch (error) {
    dispatch(actions.getRightToRentListFailure(extractError(error)));
  }
};

export default reducer;
