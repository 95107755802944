const initialState = {
  values: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    subscription: false,
  },
  isModalVisible: false,
  isActivated: false,
  isSubmitted: false,
  isLoading: false,
  invitationInfo: null,
  invitationInfoLoading: false,
  invitationInfoError: "",
  invitationAgentInfoError: "",
  error: "",
};

export const showModal = (state, {payload}) => ({
  ...state,
  isModalVisible: true,
  values: payload.values,
  error: "",
});

export const returnFormData = state => ({
  ...state,
  isModalVisible: false,
  isSubmitted: false,
  isLoading: false,
});

export const resetFormData = () => ({
  ...initialState,
});

export const resendEmailSuccess = state => ({
  ...state,
  isLoading: false,
});

export const activateUserSuccess = state => ({
  ...state,
  isActivated: true,
  isLoading: false,
});

export const registrationSuccess = state => ({
  ...state,
  isModalVisible: false,
  isSubmitted: true,
});

export const invitationInfoRequest = state => ({
  ...state,
  invitationInfoLoading: true,
});

export const invitationInfoFailure = (state, {payload}) => ({
  ...state,
  invitationInfoLoading: false,
  invitationInfoError: payload,
});

export const getInvitationInfo = (state, {payload}) => ({
  ...state,
  invitationInfo: payload,
  invitationInfoLoading: false,
});

export const getInvitationInfoByType = (state, {payload}) => ({
  ...state,
  values: {
    ...state.values,
    email: payload.email,
  },
  invitationInfoLoading: false,
});

export const invitationAgentInfoFailure = (state, {payload}) => ({
  ...state,
  invitationAgentInfoError: payload,
  invitationInfoLoading: false,
});

export const clearState = () => ({...initialState});

export default initialState;
