import {Toaster} from "@findyourcanopy/canopy-ui";

import {http} from "http/index";
import qs from "utils/query";
import analytics from "services/analytics";
import {extractError} from "redux/helpers";

import {
  formatConnectedUsers,
  formatGuaranteedRenters,
  formatInvitedUsers,
  formatJointScreening,
  formatPhoneValidation,
} from "./formatters";

export const getAcceptedList = async ({branchId}) =>
  http.get(`/agent/rent-passport${qs.stringify({branchId})}`).then(formatConnectedUsers(branchId));

export const getInvitedList = async ({branchId}) =>
  http.get(`/agent/invites${qs.stringify({branchId})}`).then(formatInvitedUsers(branchId));

export const sendInvite = async ({branchId, renterId}) =>
  http.post("/agent/invites", {branchId, renterId});

export const getFavouritesList = async ({branchId}) =>
  http
    .get(`/agent/rent-passport/favourites${qs.stringify({branchId})}`)
    .then(formatConnectedUsers(branchId));

export const getOne = async ({renterId, branchId, guarantor}) =>
  http.get(`agent/rent-passport-report/${renterId}${qs.stringify({branchId, guarantor})}`);

export const getEmployerReferenceSection = async ({renterId, branchId, guarantor}) =>
  http.get(
    `/agent/employer-reference/screening-state/${renterId}${qs.stringify(
      {branchId, guarantor: !!guarantor},
      {skipNulls: true},
    )}`,
  );

export const getLandlordReferenceSection = async ({renterId, branchId, guarantor}) =>
  http.get(
    `/agent/landlord-reference/screening-state/${renterId}${qs.stringify(
      {branchId, guarantor: !!guarantor},
      {skipNulls: true},
    )}`,
  );

export const getRightToRentSection = async ({renterId, branchId}) =>
  http.get(`/agent/right-to-rent-request/history${qs.stringify({renterId, branchId})}`);

export const getIdVerificationSection = async ({renterId, branchId}) =>
  http.get(`/agent/identity-verification${qs.stringify({renterId, branchId})}`);

export const getIdVerificationDocuments = ({branchId, guarantor, renterId, verificationId}) =>
  http.get(
    `/agent/identity-verification/${verificationId}/renter/${renterId}/documents${qs.stringify({
      branchId,
      guarantor: !!guarantor,
    })}`,
  );

export const getIDnowVerificationArchiveLink = async ({
  branchId,
  guarantor,
  renterId,
  verificationId,
}) =>
  http.get(
    `/agent/identity-verification/${verificationId}/renter/${renterId}/documents/archive${qs.stringify(
      {
        branchId,
        guarantor: !!guarantor,
      },
    )}`,
  );

export const getCreditCheckSection = async ({id, branchId = "", guarantor}) =>
  http.get(
    `/agent/credit-check/screening-state/${id}${qs.stringify({branchId, guarantor: !!guarantor})}`,
  );

/**
 * Creates guarantor request.
 * `accessLevel` is one of the available referencing types (INSTANT, FULL, etc).
 * `addonTypes` is one if the available addons (IDV, RTR, etc).
 */
export const createGuarantorRequest = async ({accessLevel, addonTypes, branchId, renterId}) =>
  http.post("agent/guarantor-request", {
    accessLevel,
    addonTypes,
    branchId,
    renterId,
  });

export const cancelGuarantorRequest = async ({requestId}) =>
  http.delete(`agent/guarantor-request/${requestId}`);

export const getGuarantors = async ({renterId, branchId, guarantor}) =>
  http.get(
    `/agent/guarantors/outcome-or-count${qs.stringify(
      {branchId, userId: renterId, guarantor: !!guarantor},
      {skipNulls: true},
    )}`,
  );

export const getUserBranchConnectionData = async ({userId, branchId, screeningType}) =>
  http.get(
    `/agent/branch-connections/branch/${branchId}/user/${userId}/details?screeningType=${screeningType}`,
  );

export const getGuaranteedRenters = async ({id}) =>
  http.get(`/agent/guarantors/income?userId=${id}`).then(formatGuaranteedRenters);

export const getUserJointScreeningData = async ({branchId, renterId}) =>
  http
    .get(`/agent/joint-screening/by-user?branchId=${branchId}&userId=${renterId}`)
    .then(formatJointScreening);

export const downloadPDF = async ({id, isGuarantor, pdfType, branchId}) =>
  http.get(
    `/pdf/rent-passport/${id}${qs.stringify({branchId, guarantor: !!isGuarantor, type: pdfType})}`,
  );

export const downloadPDFNewRef = async ({id, isGuarantor, pdfType, branchId}) =>
  http.get(
    `/pdf/rent-passport/referencing/${id}${qs.stringify({
      branchId,
      guarantor: !!isGuarantor,
      type: pdfType,
    })}`,
  );

export const sendReminder = async id => http.post(`agent/invites/${id}/send-reminder`, {});

export const cancelInvite = async id => http.delete(`agent/invites/${id}`);

export const markAsFavorite = async data => http.post("agent/rent-passport/favourite", data);

export const markAsUnFavorite = async data => http.post("agent/rent-passport/unfavourite", data);

export const getConnectedBranches = async ({renterId}) =>
  http.get(`/agent/connections/access-levels/${renterId}`);

export const getBranchScreeningConnectedBranches = async ({renterId}) =>
  http.get(`/agent/branch-connections/access-levels/${renterId}`);

export const createRightToRentRequest = async ({renterId, branchId, branchConnectionId}) =>
  http.post("/agent/right-to-rent-request", {
    renterId,
    branchId,
    branchConnectionId,
  });

export const createIdVerificationRequest = async ({renterId, branchId}) =>
  http.post("/agent/identity-verification-request/send", {
    renterId,
    branchId,
  });

export const createRightToRentComplete = async data =>
  http.post("/agent/right-to-rent-request/complete", data);

export const cancelRightToRentRequest = async ({renterId, branchId}) =>
  http.post("/agent/right-to-rent-request/cancel", {
    renterId,
    branchId,
  });

export const cancelIdVerificationRequest = async ({renterId, branchId}) =>
  http.post("/agent/identity-verification-request/cancel", {
    renterId,
    branchId,
  });

// TODO: Remove with old rp page
export const createPrimaryScreeningRequest = async ({renterId, branchId, isGuarantor}) =>
  http.post("/priority-reference-request", {renterId, branchId, isGuarantor});

export const createPrimaryScreeningRequestBranchConnection = async branchConnectionId =>
  http.post(`/priority-reference-request/branch-connection/${branchConnectionId}`);

export const editRightToRent = async ({requestId, data}) =>
  http.put(`/agent/right-to-rent-request/update/${requestId}`, data);

export const getSectionNotes = async ({item, renterId}) =>
  http.get(`/agent/${item}/admin-notes/user/${renterId}`); // NOTE: item is section name (income, rent, savings)

export const getSummaryNotesReq = async ({renterId}) => http.get(`/summary-notes/${renterId}`);

export const setMoveInDates = async data =>
  http.post("/agent/branch-connections/edit-move-in-dates", data);

export const setMoveInDetails = async data =>
  http.post("/agent/branch-connections/edit-move-in-details", data);

export const getRentPreferencesSection = async ({renterId, branchId, guarantor}) =>
  http.get(
    `agent/rental-preferences/screening-state/${renterId}${qs.stringify({
      branchId,
      guarantor: !!guarantor,
    })}`,
  );

export const getSavingsScreeningState = ({renterId, guarantor, branchId}) =>
  http.get(`/agent/savings/screening-state/${renterId}${qs.stringify({branchId, guarantor})}`);

export const getRentScreeningState = ({renterId, guarantor, branchId}) =>
  http.get(
    `/agent/rent/screening-state/${renterId}${qs.stringify(
      {branchId, guarantor: !!guarantor},
      {skipNulls: true},
    )}`,
  );

/**
 * Requests the information about the Screening state of the Income section
 */
export const getIncomeScreeningState = ({branchId, guarantor, renterId}) =>
  http.get(
    `/agent/income/screening-state/${renterId}${qs.stringify({branchId, guarantor: !!guarantor})}`,
  );

export const validateUserPhoneNumber = ({number}) =>
  http.get(`/agent/user/validate-phone/${qs.stringify({number})}`).then(formatPhoneValidation);

export const getUserPhoneHintsByEmail = ({email}) =>
  http.get(`/agent/user/phone-hints/${qs.stringify({email})}`).then(formatPhoneValidation);

export const getManualIncomeVerificationItem = ({incomeId, renterId, branchId, guarantor}) =>
  http.get(
    `/agent/income/${incomeId}/renter/${renterId}/verification-item/manual${qs.stringify({
      branchId: branchId || undefined,
      guarantor: !!guarantor,
    })}`,
  );

export const getManualSavingsVerificationItem = ({savingId, renterId, branchId, guarantor}) =>
  http.get(
    `/agent/savings/${savingId}/renter/${renterId}/verification-item/manual${qs.stringify({
      branchId: branchId || undefined,
      guarantor: !!guarantor,
    })}`,
  );

export const downloadAllIncomeDocuments = async ({incomeId, renterId}) => {
  try {
    const page = window.open("", "_self");

    const response = await http.get(
      `/agent/income/${incomeId}/renter/${renterId}/verification-item/manual/archive`,
    );

    page.location.href = response.downloadLink;
  } catch (error) {
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error.message)});
  }
};
export const downloadAllSavingsDocuments = async ({savingId, renterId}) => {
  try {
    const page = window.open("", "_self");

    const response = await http.get(
      `/agent/savings/${savingId}/renter/${renterId}/verification-item/manual/archive`,
    );

    page.location.href = response.downloadLink;
  } catch (error) {
    analytics.logErrorWithLevel("ToastOverlay", error);
    Toaster.toastConfig.showError({title: extractError(error.message)});
  }
};

export const getCategorizationData = async ({frequency, source}) =>
  http.get(`/content-manager/document-type${qs.stringify({frequency, source})}`);
