import intervalToDuration from "date-fns/intervalToDuration";
import isBefore from "date-fns/isBefore";

import {statuses} from "constants/rightToRent";

export const DATE_FORMAT = "dd / MM / yyyy";
export const DATE_FORMAT_SHORT = "dd/MM/yyyy";
export const DATE_FORMAT_MONTH = "MM / yyyy";
export const DATE_TIME_FORMAT = "dd / MM / yyyy h:mm aa";
export const FULL_MONTH_FORMAT = "dd MMM yyyy";

const getDateFromNow = originalDate => {
  const currentDate = new Date();
  const date = new Date(originalDate);

  const {years, months: originalMonths, days, hours, minutes, seconds} = intervalToDuration({
    start: date,
    end: currentDate,
  });
  const isDateBeforeNow = isBefore(date, currentDate);

  const isRestAfterDay = !!(hours || minutes || seconds);
  const isRestAfterMonth = !!(days || isRestAfterDay);

  const ceilDays = days + isRestAfterDay;
  const ceilWeeks = Math.ceil(ceilDays / 7);

  const months = years * 12 + originalMonths;

  return {
    months,
    ceilMonths: months + isRestAfterMonth,
    ceilWeeks,
    days,
    ceilDays,
    isDateBeforeNow,
  };
};

export const showExpireStatus = ({date, status}) => {
  if (!date || status === statuses.EXPIRED) {
    return false;
  }

  const {isDateBeforeNow, ceilMonths} = getDateFromNow(date);

  return isDateBeforeNow || ceilMonths <= 3;
};

export const formatExpireRtrStatus = ({date, translations}) => {
  if (!date) {
    return null;
  }

  const {ceilMonths, months, ceilWeeks, ceilDays, days, isDateBeforeNow} = getDateFromNow(date);

  if (isDateBeforeNow) {
    return {
      text: `${translations.expiring} ${translations.today}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (ceilMonths > 3) {
    return null;
  }

  if (ceilMonths >= 2) {
    return {
      text: `${translations.expiringIn} ${ceilMonths} ${translations.months}`,
      type: "warning",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (months === 1 || ceilWeeks > 3) {
    return {
      text: `${translations.expiringIn} ${ceilMonths} ${translations.month}`,
      type: "warning",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (ceilDays >= 14) {
    return {
      text: `${translations.expiringIn} ${ceilWeeks} ${translations.weeks}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (ceilDays >= 2) {
    return {
      text: `${translations.expiringIn} ${ceilDays} ${translations.days}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  if (days === 1) {
    return {
      text: `${translations.expiringIn} ${ceilDays} ${translations.day}`,
      type: "error",
      iconName: "attention-fill",
      isPrimary: true,
    };
  }

  return {
    text: `${translations.expiring} ${translations.today}`,
    type: "error",
    iconName: "attention-fill",
    isPrimary: true,
  };
};
