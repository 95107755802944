import {getApp, initializeApp} from "firebase/app";

const defaultConfig = process.env.REACT_APP_FIREBASE_CONFIG || "{}";

export const initializeFirebaseApp = (appName = undefined, config = defaultConfig) => {
  try {
    if (!config || config === "{}") {
      throw new Error("Firebase config was not provided");
    }

    let app;

    try {
      app = initializeApp(JSON.parse(config), appName);

      return app;
    } catch (error) {
      if (error.code === "app/duplicate-app") {
        app = getApp(appName);

        return app;
      }

      throw error;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`${error.name}: ${error.message}`, error);

    throw error;
  }
};
