const formatInactiveUser = ({translations}) => data => {
  const tenants = data.jointRenters.map(el => {
    if (!el.isActiveUser) {
      return {
        ...el,
        fullName: translations.inviteSent,
      };
    }

    return el;
  });

  return {
    ...data,
    jointRenters: tenants,
  };
};

export default formatInactiveUser;
