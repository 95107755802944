const initialState = {
  list: [],
  activeBranchId: null,
  isAllBranchesMode: true,
  isLoading: false,
  error: "",
};

export const getBranchesSuccess = (state, {payload}) => ({
  ...state,
  list: payload,
  isLoading: false,
  error: "",
});

export const setActiveBranchId = (state, {payload}) => ({
  ...state,
  activeBranchId: payload,
  isAllBranchesMode: false,
});

export const setAllBranchesMode = state => ({
  ...state,
  activeBranchId: null,
  isAllBranchesMode: true,
});

export const clearState = () => ({...initialState});

export default initialState;
