import {getAnalytics, logEvent, setUserId, setUserProperties} from "firebase/analytics";

import {initializeFirebaseApp} from "services/firebase";

const {title} = document;

document.title = window.location.pathname;

class Analytics {
  #analytics;

  #gtagId;

  constructor() {
    const app = initializeFirebaseApp();

    this.#gtagId = app?.options?.measurementId;
    this.#analytics = getAnalytics(app);
  }

  /**
   * Log a custom event with optional parameters
   *
   * @param {string} event - event names should contain 1 to 32 alphanumeric characters or underscores
   * @param {object} params - up to 100 characters is the maximum character length supported for event parameters
   * @param {object} options - is one or more parameter-value pairs
   */
  logEvent = (event, params = {}, options = {}) => {
    logEvent(this.#analytics, event, params, options);
  };

  /**
   * Log a custom event with optional parameters
   *
   * @param {string} errorType - ENUM FullPageWarning || ToastOverlay || SubsectionWarning
   * @param {object} error - error object
   */
  logErrorWithLevel = (errorType, error) => {
    const params = {
      ErrorType: errorType,
      LOCATION: window.location.href,
      SCREEN_NAME: window.location.pathname,
      ErrorCode: error.code || 0,
    };

    logEvent(this.#analytics, "UserError", params);
  };

  /**
   * Sets the current screen name.
   *
   * @param {string} screenName - screen name to set
   */
  setCurrentScreen = screenName => {
    document.title = title;

    if (window.gtag) {
      window.gtag("config", this.#gtagId, {
        page_path: screenName,
        page_title: screenName,
        screen_name: screenName,
        update: true,
      });
    }

    // FIXME: @deprecated https://firebase.google.com/docs/reference/js/analytics.md#setcurrentscreen
    // this.#analytics.setCurrentScreen(screenName); // GA does not respect screen_name. May change in future
  };

  /**
   * Sets current user information
   *
   * @param {object} user
   */
  setUser = user => {
    if (user && Object.keys(user).length) {
      const userProps = {
        ...user,
      };

      setUserId(this.#analytics, user.id ?? "");
      setUserProperties(this.#analytics, userProps);
    } else {
      setUserId(this.#analytics, "");
      setUserProperties(this.#analytics, {});
    }
  };

  /**
   * Gives a user a unique identification
   *
   * @param {string, null} id
   */
  setUserId = id => {
    setUserId(this.#analytics, id ?? "");
  };

  /**
   * Sets a key/value pair of data on the current user
   *
   * @param {string} name
   * @param {string} value
   */
  setUserProperty = (name, value) => {
    setUserProperties(this.#analytics, {[name]: value});
  };

  /**
   * Sets multiple key/value pairs of data on the current user.
   *
   * @param {object} fieldMapping
   */
  setUserProperties = fieldMapping => {
    setUserProperties(this.#analytics, fieldMapping);
  };
}

export default new Analytics();
