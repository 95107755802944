import styled from "styled-components";
import {Text, utils} from "@findyourcanopy/canopy-ui";

export const StatusHeading = styled(Text.Heading)`
  color: ${({theme}) => theme.primary};
  font-size: 120px;
  font-weight: bold;
  letter-spacing: 1.33px;
  ${utils.responsive.mobileL} {
    font-size: 100px;
  }
`;

export const Description = styled(Text.P)`
  padding-top: 20px;
  ${utils.responsive.mobileL} {
    font-size: 16px;
  }
`;
