import uppercaseFirstLetter from "utils/uppercaseFirstLetter";
import history from "utils/history";
import getTranslations from "utils/locales";
import {backendErrors} from "constants/branchSelector";

const locales = getTranslations();

export const formatErrorFromBackend = (error = "") =>
  error.length > 0 ? `${uppercaseFirstLetter(error)}. ` : "";

export const sessionExpired = () => {
  const from = {
    pathname: "/auth/logout",
    state: {
      from: {
        pathname: window.location.pathname,
        state: {
          reason: locales.modules.errorPage.unauthorized.reason,
        },
      },
    },
  };

  return history.push(from);
};

export const branchAccessDenied = () => {
  const from = {
    pathname: "/dashboard/company",
    state: {
      reason: backendErrors.permissionDeniedToBranch,
    },
  };

  return history.push(from);
};
