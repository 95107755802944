import qs from "utils/query";

import {http} from "..";

export const getPropertiesList = requestParams =>
  http.get(`/agent/properties${qs.stringify(requestParams, {skipNulls: true})}`);

export const getPropertyDetails = ({propertyId}) => http.get(`/agent/properties/${propertyId}`);

/**
 * Create Property on List of properties
 * @param {Object} propertyData
 * @param {string?} propertyData.flatNumber
 * @param {string?} propertyData.houseNumber
 * @param {string?} propertyData.houseName
 * @param {string} propertyData.street
 * @param {string?} propertyData.county
 * @param {string} propertyData.city
 * @param {string} propertyData.postcode
 * @param {string?} propertyData.country
 * @param {string?} propertyData.internalCode
 * @param {string} propertyData.branchId
 * @param {number} propertyData.amount
 * @param {"WEEKLY" | "MONTHLY"} propertyData.rentFrequency
 */
export const postProperty = propertyData => http.post("/agent/properties", propertyData);

/**
 * Update Property on Property Details
 * @param {Object} props
 * @param {Object} props.propertyData
 * @param {string?} props.propertyData.flatNumber
 * @param {string?} props.propertyData.houseNumber
 * @param {string?} props.propertyData.houseName
 * @param {string} props.propertyData.street
 * @param {string?} props.propertyData.county
 * @param {string} props.propertyData.city
 * @param {string} props.propertyData.postcode
 * @param {string?} props.propertyData.country
 * @param {string?} props.propertyData.internalCode
 * @param {string} props.propertyData.branchId
 * @param {number} props.propertyData.amount
 * @param {"WEEKLY" | "MONTHLY"} props.propertyData.rentFrequency
 * @param {string} props.propertyId
 */
export const putProperty = ({propertyData, propertyId}) =>
  http.put(`/agent/properties/${propertyId}`, propertyData);

/**
 * @typedef {Object} JointRentersDetails
 * @property {string} id
 * @property {number} rentAffordability
 * @property {string} fullName
 */

/**
 * @typedef {Object} GetPropertyDetails
 * @property {string} id
 * @property {string} name
 * @property {string} internalCode
 * @property {JointRentersDetails[]} jointRenters
 */

/**
 * Requests Tenancy attached to the Property.
 */
export const getTenanciesLinkedToProperty = ({propertyId}) =>
  http.get(`/agent/properties/${propertyId}/tenancies`);

/**
 * @typedef {Object} GetSearchTenancies
 * @property {string} branchId
 * @property {string} tenancyName
 * @property {string} internalCode
 * @property {string} address
 * @property {string} key
 * @property {{rentAffordability: number, firstName: string, lastName: string, id: string }} members
 * @property {string} renterName
 */

/**
 * Search created tenancies. Used on add tenancies to property on Property details page
 * @param {Object} props
 * @param {string} props.branchId
 * @param {string} props.search - internalCode, renterName, address, tenancyName to find Tenancy
 * @returns {Promise<GetSearchTenancies>}
 */
export const getSearchTenancies = ({branchId, search}) =>
  http.get(`/agent/tenancies${qs.stringify({branchId, search}, {skipNulls: true})}`);

/**
 * Links array of Tenancies to Property.
 */
export const linkTenancyToProperty = ({tenancyIds, propertyId}) =>
  http.post("/agent/tenancies/link-tenancies", {tenancyIds, propertyId});

/**
 * @typedef {Object} GetPropertyRenters
 * @property {string} firstName
 * @property {string} lastName
 * @property {string} email
 * @property {string} phone
 * @property {string} branchConnectionId
 * @property {string} tier
 * @property {string} overallStatus
 * @property {number} rentAffordability
 */

/**
 * List of renters linked with property. Used on renters list. Property derails page
 * @param {Object} props
 * @param {string} props.propertyId
 * @returns {Promise<GetPropertyRenters[]>}
 */

export const getPropertyRenters = ({propertyId}) =>
  http.get(`/agent/properties/${propertyId}/link-renter-details`);

/**
 * Get Invited Renters by branch. Used for display data of Invited renters on renters list. Used with getPropertyRenters
 * @param {Object} props
 * @param {string} props.branchId
 */
export const getInvitedRentersList = async ({branchId}) =>
  http.get(`/agent/branch-connections/invites?branchId=${branchId}`);

/**
 * Requests the list of Properties, associated with the Renter
 */
export const getRenterProperties = async (renterId, branchId) =>
  http.get(`/agent/properties/renter/${renterId}${qs.stringify({branchId}, {skipNulls: true})}`);

/**
 * Requests attaching Branch Connections and/or Renter Invites to the Property
 */
export const linkToProperty = async (propertyId, {branchConnectionId, renterInviteId}) =>
  http.post(`/agent/properties/${propertyId}/link-renter-details`, {
    branchConnectionId,
    renterInviteId,
  });

/**
 * Requests detaching Branch Connections and/or Renter Invites from the Property
 */
export const unlinkFromProperty = async (propertyId, {branchConnectionId, renterInviteId}) =>
  http.delete(`/agent/properties/${propertyId}/link-renter-details`, {
    data: {branchConnectionId, renterInviteId},
  });

/**
 * Unlinks Tenancy from Property.
 */
export const unlinkTenancyFromProperty = ({tenancyId, propertyId}) =>
  http.post("/agent/tenancies/unlink", {tenancyId, propertyId});

/**
 * Updates Property state.
 */
export const updatePropertyState = ({propertyId, state}) =>
  http.patch(`/agent/properties/${propertyId}/state`, {state});

export const lookupAddressByPostcode = async postCode =>
  http.post("/lookup-address", {address: {postCode, countryCode: "GB"}});

/**
 * Submits Utility Switch for the Property.
 */
export const submitUtilitySwitch = async ({
  tenancyId,
  propertyId,
  confirmedMoveInDate,
  utilitiesManagedBy,
  landlordId,
  isVacantProperty,
  moveOutDateOfPreviousTenants,
  notifyCouncils,
  notifyWaterSupplier,
  notifyGasAndElectricity,
}) =>
  http.post(`/agent/utility-management/property/${propertyId}`, {
    tenancyId,
    confirmedMoveInDate,
    utilitiesManagedBy,
    landlordId,
    isVacantProperty,
    ...(isVacantProperty ? {} : {moveOutDateOfPreviousTenants}),
    notifyCouncils,
    notifyWaterSupplier,
    notifyGasAndElectricity,
  });
