import {compareDesc} from "date-fns";
import isString from "lodash/isString";

import makeFullName, {makeFullNameShortcut} from "utils/makeFullName";
import {formatDate, parseAndGetTime} from "utils/date";
import {statuses} from "constants/rentPassport";

export const formatInvitedList = branchId => ({total, list}) => ({
  total,
  list: list
    .map(
      ({
        id,
        email,
        invitedAt,
        branches,
        numberOfReminders,
        firstName,
        lastName,
        phone,
        screeningType,
      }) => {
        const firstNameShortcut = isString(firstName) && firstName.charAt(0);
        const lastNameShortcut = isString(lastName) && lastName.charAt(0);

        const formattedName = makeFullNameShortcut({
          firstName,
          lastName,
          defaultValue: firstNameShortcut || lastNameShortcut || "-",
        });
        const {fullName, ...userNames} = makeFullName({
          firstName,
          lastName,
          withOriginalValues: true,
        });

        return {
          id,
          fullName: fullName || firstName || lastName || "-",
          formattedName,
          status: branchId ? statuses.INVITE_SENT : undefined,
          numberOfReminders,
          screeningType,
          phone: phone || "-",
          email,
          // used for search. Not visible for UI
          branchName: branches ? branches.reduce((sum, curr) => sum + curr.name, "") : undefined,
          dateInvited: formatDate({date: invitedAt}),
          search: {
            fullName: userNames,
          },
          sort: {
            email: email.toLowerCase(),
            dateInvited: parseAndGetTime(invitedAt),
          },
        };
      },
    )
    .sort((a, b) => compareDesc(a.sort.dateInvited, b.sort.dateInvited)),
});
