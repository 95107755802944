import {rentFrequency} from "constants/jointScreening";

const initialState = {
  data: {
    name: "",
    paymentFrequency: rentFrequency.get("WEEKLY"),
    rentAmount: "0",
    monthlyRentAmount: "0",
    jointRenters: [],
    branchId: "",
  },
  lastSuccessfulBranchId: "",
  isLoading: false,
  error: "",
};

export const getJointScreeningSuccess = (state, {payload}) => ({
  ...state,
  data: payload.data,
  lastSuccessfulBranchId: payload.lastSuccessfulBranchId,
  isLoading: false,
});

export const getInactiveJRentersConnectionStatusRequest = (state, {payload}) => ({
  ...state,
  data: {...state.data, jointRenters: payload},
});

export const getInactiveJRentersConnectionStatusSuccess = (state, {payload}) => ({
  ...state,
  data: {...state.data, jointRenters: payload},
});

export const setDateFinalisedAt = state => ({
  ...state,
  data: {...state.data, finalisedAt: new Date()},
});

export const getInactiveJRentersConnectionStatusFailure = (state, {payload}) => ({
  ...state,
  data: {...state.data, jointRenters: payload.jointRenters},
  error: payload.error,
});

export const getJointScreeningFailure = (state, {payload}) => ({
  ...state,
  error: payload,
  isLoading: false,
  lastSuccessfulBranchId: state.lastSuccessfulBranchId,
});

export const clear = state => ({
  ...initialState,
  lastSuccessfulBranchId: state.lastSuccessfulBranchId,
});

export default initialState;
