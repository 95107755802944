const initialState = {
  isUserSnapInitialized: false,
};

export const setIsUserSnapInitialized = (state, {payload}) => ({
  ...state,
  isUserSnapInitialized: payload,
});

export const resetSlice = () => ({...initialState});

export default initialState;
