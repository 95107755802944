import {createSlice} from "@reduxjs/toolkit";

import {getCompanyBranding, getCompanyOverview, getLIReferralLink} from "http/company";
import {getPropertiesList} from "http/properties";
import {getVerificationProviderInformation} from "http/verification-provider";
import {logout} from "redux/auth/actions";
import {extractError} from "redux/helpers";
import {startLoading, loadingFailed} from "redux/helpers/actions";
import remoteConfig from "services/remote-config";
import {LANDLORD_INSURANCE_REFERRAL_LINK} from "services/remote-config/constants";

import initialState, * as handlers from "./handlers";

const {actions, reducer} = createSlice({
  name: "company",
  initialState,
  reducers: {
    getCompanyRequest: startLoading,
    getCompanyRequestFailure: loadingFailed,
    getCompanyRequestSuccess: handlers.getCompanyDataSuccess,
    getLIReferralLinkRequest: startLoading,
    getLIReferralLinkRequestFailure: loadingFailed,
    getLIReferralLinkRequestSuccess: handlers.getLIReferralLinkRequestSuccess,
    getLISetUpRequestSuccess: handlers.getLISetUpRequestSuccess,
    getPropertiesCountFailure: loadingFailed,
    getPropertiesCountRequest: startLoading,
    getPropertiesCountSuccess: handlers.getPropertiesCountSuccess,
    requestCompanyBrandingFailure: handlers.requestCompanyBrandingFailed,
    requestCompanyBrandingSuccess: handlers.requestCompanyBrandingSuccess,
    requestVerificationProviderInformationLoading: startLoading,
    requestVerificationProviderInformationFailure: loadingFailed,
    requestVerificationProviderInformationSuccess:
      handlers.requestVerificationProviderInformationSuccess,
    updateBranchesCount: handlers.updateBranchesCount,
  },
  extraReducers: {
    [logout]: handlers.clearState,
  },
});

export const getCompany = () => async dispatch => {
  try {
    dispatch(actions.getCompanyRequest());

    const data = await getCompanyOverview();

    dispatch(actions.getCompanyRequestSuccess(data));
  } catch (error) {
    dispatch(actions.getCompanyRequestFailure(extractError(error)));
  }
};

export const getLandlordInsuranceReferralLink = () => async dispatch => {
  try {
    dispatch(actions.getLIReferralLinkRequest());

    const isLinkSetUp = await remoteConfig.getBoolean(LANDLORD_INSURANCE_REFERRAL_LINK);

    let data = null;

    dispatch(actions.getLISetUpRequestSuccess(isLinkSetUp));

    if (isLinkSetUp) {
      data = (await getLIReferralLink())?.referralLink || null;
    }

    dispatch(actions.getLIReferralLinkRequestSuccess(data));
  } catch (error) {
    dispatch(actions.getLIReferralLinkRequestFailure(extractError(error)));
  }
};

export const requestCompanyBranding = companyId => async dispatch => {
  try {
    const data = await getCompanyBranding(companyId);

    dispatch(actions.requestCompanyBrandingSuccess(data));
  } catch (error) {
    dispatch(actions.requestCompanyBrandingFailure(extractError(error)));
  }
};

export const requestVerificationProviderInformation = () => async dispatch => {
  try {
    dispatch(actions.requestVerificationProviderInformationLoading());

    const data = await getVerificationProviderInformation();

    dispatch(actions.requestVerificationProviderInformationSuccess(data));
  } catch (error) {
    dispatch(actions.requestVerificationProviderInformationFailure(extractError(error)));
  }
};

export const updateBranchesCount = updatedBranch => async dispatch => {
  if (updatedBranch) {
    dispatch(actions.updateBranchesCount());
  }
};

export const getPropertiesCount = () => async dispatch => {
  try {
    dispatch(actions.getPropertiesCountRequest());
    const data = await getPropertiesList();

    dispatch(actions.getPropertiesCountSuccess(data.total));
  } catch (error) {
    dispatch(actions.getPropertiesCountFailure(extractError(error)));
  }
};

export default reducer;
